import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: 0 1px 3px rgb(0 0 0/12%), 0 1px 2px rgb(0 0 0/24%);
  border-radius: 4px;
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto;
  flex-grow: 2;

  padding: 15px;

  @media ${(props) => props.theme.breakpoints.desktopLarge} {
    padding: 20px;
  }
`;

export const ChartOuterContainer = styled.div`
  overflow: auto;
`;
export const ChartSecondOuterContainer = styled.div`
  display: grid;
  height: 100%;
  min-height: 250px;

  @media ${(props) => props.theme.breakpoints.desktopLarge} {
    min-height: 400px;
  }
`;

export const ChartContainer = styled.div`
  position: relative;
  margin: auto;
  width: 100%;
  height: 100%;
`;

export const TitleRecommendations = styled.h2``;

export const TotalRecommendations = styled.div`
  background: ${(props) => props.theme.colors.lightGray};
  display: flex;
  align-items: center;
  border-radius: 8px;

  margin: ${(props) => `${props.theme.padding.small * 1.5}px 0px`};
  padding: ${(props) => `${props.theme.padding.small * 1.5}px ${props.theme.padding.small * 2}px`};
`;

export const RecommendationLabel = styled.h1``;

export const RecommendationsLink = styled(NavLink)`
  margin-left: auto;
  color: ${(props) => props.theme.colors.darkGray};
  text-decoration: none;
`;

export const StyledIcon = styled(FontAwesomeIcon).attrs((props) => ({
  size: '1x',
  color: props.theme.colors.darkGray,
}))`
  padding-left: ${(props) => `${props.theme.padding.small / 2}px`};
  padding-top: ${(props) => `${props.theme.padding.small / 2}px`};
`;
