class ActionResponse {
  success: boolean;
  message: string;
  data?: any;

  constructor({ message, success, data = null }: { message: string; success: boolean; data?: any }) {
    this.success = success;
    this.message = message;
    this.data = data;
  }

  static OK: ActionResponse = new ActionResponse({ success: true, message: '' });
}

interface Action {
  id: string;
  label: string;
  execute(input?: any): ActionResponse;
}

export { ActionResponse };
export type { Action };
