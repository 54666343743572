import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import Button from '../Button/button';
import { ButtonContainer, Container, InnerContainer, Title } from './notFound.styles';

const NotFound = () => {
  const { t } = useTranslation();

  const params = useParams();
  const wrongURL = params['*'];
  const navigate = useNavigate();

  return (
    <Container>
      <InnerContainer>
        <Title>{t('Global.PageNotFoundMessage')}</Title>
        <ButtonContainer>
          <Button
            buttonName="GO_HOME"
            onClick={() => {
              navigate('/');
            }}
            extraLogData={{ wrongURL }}>
            {t('Global.GoHome')}
          </Button>
        </ButtonContainer>
      </InnerContainer>
    </Container>
  );
};

export default NotFound;
