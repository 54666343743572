import { connect } from 'react-redux';

import { withValidConfiguration } from '@Components';
import { RootState } from '@Store';
import Configure from './configureLink';

const ConfigureContainer = (props) => {
  return <Configure {...props} />;
};

const mapStateToProps = (state: RootState) => {
  const { client } = state;
  const { configuration } = client;
  const { configurationLinks } = configuration;
  return { configurationLinks };
};

export default withValidConfiguration(connect(mapStateToProps)(ConfigureContainer), 'configurationLinks');
