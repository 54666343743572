import styled from 'styled-components';

import { IconClose } from '@Assets';

export const Container = styled.div``;

export const StyledIconClose = styled(IconClose)`
  z-index: 1;
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  border-radius: 2px;

  width: 32px;
  height: 32px;
  path {
    stroke: ${(props) => props.theme.colors.lightBlack};
  }

  @media ${(props) => props.theme.breakpoints.mobile} {
    width: 24px;
    height: 24px;
  }
`;

export const InnerContainer = styled.div`
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FrameContainer = styled.div`
  position: relative;
  max-width: 70%;
  max-height: 70%;

  box-shadow: 0px 10px 40px rgba(255, 255, 255, 0.3);
  background-color: ${(props) => props.theme.colors.background};
  border-radius: 10px;
  overflow: auto;
  border: 1px solid ${(props) => props.theme.colors.darkGray};
  padding: 15px 0px 15px 15px;
  box-sizing: border-box;
  flex: 1;
  flex-shrink: 1;
  width: fit-content;
  display: flex;
  flex-direction: column;

  @media ${(props) => props.theme.breakpoints.mobile} {
    max-width: 90%;
    max-height: 90%;
    padding: 10px 0px;
  }
`;

export const Title = styled.h1`
  font-weight: 1000;
  font-size: 30px;
  line-height: 1;
`;

export const CardsOuterContainer = styled.div`
  overflow-y: auto;
`;

export const CardsContainer = styled.div`
  margin-top: 20px;

  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(350px, 400px));
  grid-auto-flow: dense;

  min-width: 0;
  min-height: 0;

  justify-content: center;
  align-items: center;

  justify-content: space-evenly;
  justify-items: center;
  align-content: space-evenly;
  align-items: center;

  @media ${(props) => props.theme.breakpoints.mobile} {
    grid-template-columns: repeat(auto-fit, minmax(300px, 325px));
  }
`;
