import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const StyledIcon = styled(FontAwesomeIcon)`
  display: flex;
  margin: auto;
`;

export const Paragraph = styled.p`
  margin-top: 15px;
  line-height: 1.8;
`;

export const Title = styled.h2`
  margin: 0px 0px 20px 0px;
  text-align: left;
  color: black;
  white-space: pre-line;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const InnerContainer = styled.div`
  margin: auto;
  max-width: 1000px;
  padding-bottom: 150px;
`;
