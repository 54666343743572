import { useTranslation } from 'react-i18next';

import Loading from '../Loading/loading';
import MessagePopup from '../MessagePopup/messagePopup';
import { Container, InputFile, LoadingDiv, Title, TitleContainer, UploadButton } from './manualUploadCard.styles';

interface ManualUploadCardProps {
  title: string;
  uploadFiles: (input: any) => void;
  isUploading: boolean;
  errorMessage: boolean;
}

const ManualUploadCard = ({ title, uploadFiles, isUploading, errorMessage }: ManualUploadCardProps) => {
  const { t } = useTranslation();
  const inputId = `input${title}`;

  return (
    <Container>
      {errorMessage && <MessagePopup message={t('ManualUpload.Error')} />}
      <TitleContainer>
        <Title>{title}</Title>
      </TitleContainer>
      {!isUploading && (
        <UploadButton htmlFor={inputId}>
          {t('ManualUpload.Upload')}
          <InputFile id={inputId} type="file" multiple accept="*" onChange={uploadFiles} />
        </UploadButton>
      )}
      {isUploading && (
        <LoadingDiv>
          <Loading width={30} height={30} title={t('ManualUpload.Uploading')} color="#808080" />
        </LoadingDiv>
      )}
    </Container>
  );
};

export default ManualUploadCard;
