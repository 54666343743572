import { useTranslation } from 'react-i18next';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

import { MessageIcon } from '@Components';

const Unauthorized = () => {
  const { t } = useTranslation();
  const message = t('Global.UnathorizedMessage');
  return <MessageIcon message={message} icon={faTriangleExclamation} iconColor="#AAAA00" iconSize="3x" />;
};

export default Unauthorized;
