import { connect } from 'react-redux';

import { NotFound } from '@Components';
import { Claim, Permission } from '@Models';
import { RootState } from '@Store';
import ManualUpload from './manualUpload';

interface ManualUploadProps {
  hasPermission: boolean;
  manualUpload?: string[];
}

const ManualUploadContainer = ({ hasPermission, manualUpload }: ManualUploadProps) => {
  if (!hasPermission || !manualUpload) return <NotFound />;

  return <ManualUpload clients={manualUpload} />;
};

const mapStateToProps = (state: RootState) => {
  const { authorization } = state;
  const { permissions, claims } = authorization;
  const manualUpload = claims && claims[Claim.ManualUpload];
  const hasPermission = permissions.includes(Permission.ManualUpload);

  return { hasPermission, manualUpload };
};

export default connect(mapStateToProps)(ManualUploadContainer);
