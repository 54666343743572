import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background: hsl(220deg 14% 97%);
  background-color: white;
  box-shadow: 0 1px 3px rgb(0 0 0/12%), 0 1px 2px rgb(0 0 0/24%);
  border: 1px solid #eee;
  border-radius: 6px;
  box-sizing: border-box;
  min-height: 100px;
  width: 100%;
  margin: 0;
  transition: 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55);

  padding: 15px;

  @media ${(props) => props.theme.breakpoints.desktopLarge} {
    padding: 15px 20px;
  }
`;

export const InnerContainer = styled.p`
  margin: auto 0px;
  padding: 0px;
  white-space: pre-line;
`;

export const ProductDescription = styled.span`
  font-weight: 700;
`;

export const Title = styled.h2`
  margin-left: ${(props) => `${props.theme.padding.small}px`};
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
