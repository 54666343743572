import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu, MenuItem } from '@szhsin/react-menu';

import { StyledMenu } from './menu.styles';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/zoom.css';

interface Option {
  id: string;
  label: string;
  onAction: () => void;
}
interface MenuProps {
  options: Option[];
  className: string;
}

const MenuComponent = (props: MenuProps) => {
  const { options, className } = props;
  return (
    <div className={`text-center ${className}`}>
      <Menu
        transition
        menuButton={() => (
          <div>
            <FontAwesomeIcon icon={faEllipsisV} color="black" />
          </div>
        )}>
        {options.map((action) => (
          <MenuItem
            key={action.id}
            onClick={() => {
              action.onAction();
            }}>
            {action.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default MenuComponent;
