import OpenAiAssistant, { OpenAiAssistantProps } from '../OpenAiAssistant/openAiAssistant.container';
import { PromptInput } from './recommendationAiAssistant.styles';

interface RecommendationAiAssistantProps extends OpenAiAssistantProps {
  showPrompt: boolean;
  setPrompt: (input: string) => void;
  isLoading: boolean;
}

const RecommendationAiAssistant = ({
  showPrompt,
  setPrompt,
  prompt,
  items,
  isLoading,
}: RecommendationAiAssistantProps) => {
  return (
    <>
      {showPrompt && (
        <PromptInput value={prompt} onInput={(e) => setPrompt('value' in e.target ? String(e.target.value) : '')} />
      )}
      <OpenAiAssistant prompt={prompt} items={items} isLoading={isLoading} />
    </>
  );
};

export default RecommendationAiAssistant;
