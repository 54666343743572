import { Container, Title, Value } from './statusIndicator.styles';

interface StatusIndicatorProps {
  title: string;
  value: string;
}

const StatusIndicator = ({ title, value }: StatusIndicatorProps) => {
  return (
    <Container>
      <Title>{title}</Title>
      <Value>{value}</Value>
    </Container>
  );
};

export type { StatusIndicatorProps };

export default StatusIndicator;
