import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { showErrorToast, showSuccessToast } from '@Helpers';

import { ProviderCredential } from '@Models';
import { bopsApi } from '@Network';
import Button from '../Button/button';
import {
  ButtonsContainer,
  Container,
  ControlWrapper,
  EyeIcon,
  Form,
  IconContainer,
  Input,
  InputWrapper,
  Label,
  OuterContainer,
  StyledImage,
  Title,
} from './credentialCard.styles';

interface CredentialCardProps {
  icon: string;
  name: string;
  credential: ProviderCredential;
  showUsername?: boolean;
  showPassword?: boolean;
  showToken?: boolean;
  showAccount?: boolean;
}

const CredentialCard = ({
  icon,
  name,
  credential,
  showUsername,
  showPassword,
  showToken,
  showAccount,
}: CredentialCardProps) => {
  const { t } = useTranslation();
  const { connectorType, accountEmail, accountPassword, token, id, account } = credential;

  const [newUsername, setUsername] = useState(accountEmail);
  const [newPassword, setPassword] = useState(accountPassword);
  const [newToken, setToken] = useState(token);
  const [newAccount, setAccount] = useState(account);
  const [isPasswordVisible, setPasswordVisibility] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [enableSave, setEnableSave] = useState(true);

  useEffect(() => {
    const allFieldsHaveValue =
      (newUsername || !showUsername) &&
      (newPassword || !showPassword) &&
      (newToken || !showToken) &&
      (newAccount || !showAccount);
    setEnableSave(!!allFieldsHaveValue);
  }, [newUsername, newPassword, newToken, newAccount]);

  const handleSave = (e) => {
    e.preventDefault();

    bopsApi
      .postUpdateCredentials({
        connectorType,
        accountEmail: newUsername,
        accountPassword: newPassword,
        token: newToken,
        account: newAccount ?? '',
        id,
      })
      .then(() => {
        showSuccessToast(t('Credentials.Success'));
        setIsEditing(false);
      })
      .catch((error) => {
        showErrorToast(t('Credentials.Error'));
      });
  };

  const togglePasswordVisibility = () => {
    setPasswordVisibility((prevShowPassword) => !prevShowPassword);
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    setIsEditing(true);
  };

  const handleCancel = (e) => {
    e.preventDefault();
    setUsername(accountEmail);
    setPassword(accountPassword);
    setToken(token);
    setIsEditing(false);
  };

  const extraLogData = { connectorType };

  return (
    <OuterContainer>
      <Container>
        <IconContainer>
          <StyledImage src={icon} alt={`${connectorType}-icon`} />
          <Title>{name}</Title>
        </IconContainer>
        <Form>
          {showUsername && (
            <ControlWrapper>
              <Label htmlFor={`${connectorType}-username`}>{t('Credentials.Username')}</Label>
              <Input
                type="text"
                id={`${connectorType}-username`}
                value={newUsername}
                onChange={(e) => setUsername(e.target.value)}
                disabled={!isEditing}
              />
            </ControlWrapper>
          )}

          {showPassword && (
            <ControlWrapper>
              <Label htmlFor={`${connectorType}-password`}>{t('Credentials.Password')}</Label>
              <InputWrapper>
                <Input
                  type={isPasswordVisible ? 'text' : 'password'}
                  id={`${connectorType}-password`}
                  value={newPassword}
                  onChange={(e) => setPassword(e.target.value)}
                  disabled={!isEditing}
                />
                <EyeIcon onClick={togglePasswordVisibility}>
                  <FontAwesomeIcon icon={isPasswordVisible ? faEyeSlash : faEye} />
                </EyeIcon>
              </InputWrapper>
            </ControlWrapper>
          )}

          {showToken && (
            <ControlWrapper>
              <Label htmlFor={`${connectorType}-token`}>{t('Credentials.Token')}</Label>
              <Input
                type="text"
                id={`${connectorType}-token`}
                value={newToken}
                onChange={(e) => setToken(e.target.value)}
                disabled={!isEditing}
              />
            </ControlWrapper>
          )}
          {showAccount && (
            <ControlWrapper>
              <Label htmlFor={`${connectorType}-account`}>{t('Credentials.Account')}</Label>
              <Input
                type="text"
                id={`${connectorType}-account`}
                value={newAccount}
                onChange={(e) => setAccount(e.target.value)}
                disabled={!isEditing}
              />
            </ControlWrapper>
          )}
          <ButtonsContainer>
            {!isEditing ? (
              <Button
                height="40px"
                fontSize="0.8rem"
                minWidth="75px"
                buttonName="CREDENTIALS_UPDATE"
                extraLogData={extraLogData}
                onClick={handleUpdate}>
                {t('Credentials.Update')}
              </Button>
            ) : (
              <>
                <Button
                  height="40px"
                  fontSize="0.8rem"
                  minWidth="75px"
                  buttonName="CREDENTIALS_CANCEL"
                  extraLogData={extraLogData}
                  onClick={handleCancel}
                  primary={false}>
                  {t('Credentials.Cancel')}
                </Button>
                <Button
                  height="40px"
                  fontSize="0.8rem"
                  minWidth="75px"
                  buttonName="CREDENTIALS_SAVE"
                  extraLogData={extraLogData}
                  onClick={handleSave}
                  disabled={!enableSave}>
                  {t('Credentials.Save')}
                </Button>
              </>
            )}
          </ButtonsContainer>
        </Form>
      </Container>
    </OuterContainer>
  );
};

export type { CredentialCardProps };
export default CredentialCard;
