import styled from 'styled-components';

export const Title = styled.h2`
  margin: 0px 0px 20px 0px;
  text-align: center;
  color: black;
  white-space: pre-line;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const InnerContainer = styled.div`
  margin: auto;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 120px;
  margin: auto;
`;
