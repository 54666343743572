import styled from 'styled-components';

/* eslint-disable import/prefer-default-export */
export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 40px;
  z-index: 10;
  margin-top: 5px;
`;
