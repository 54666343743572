import { Square } from './squareIcon.styles';

interface SquareIconProps {
  backgroundColor?: string;
}

const SquareIcon = ({ backgroundColor }: SquareIconProps) => {
  return <Square backgroundColor={backgroundColor} />;
};

export default SquareIcon;
