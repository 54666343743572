import { useEffect, useState } from 'react';

import OpenAiAssistant from './openAiAssistant';

interface OpenAiItemData {
  title: string;
  subtitle: string;
  payload: string;
}

interface OpenAiAssistantProps {
  items: OpenAiItemData[];
  prompt: string;
  isLoading?: boolean;
}

const OpenAiAssistantContainer = ({ items, prompt, isLoading }: OpenAiAssistantProps) => {
  const [promptText, setPromptText] = useState<string>(prompt);

  useEffect(() => {
    if (prompt) setPromptText(prompt);
  }, [prompt]);

  const itemList = items.map((item) => ({
    ...item,
    prompt: promptText,
    content: 'content',
  }));

  return <OpenAiAssistant itemList={itemList} isLoading={isLoading} />;
};

export default OpenAiAssistantContainer;

export type { OpenAiAssistantProps, OpenAiItemData };
