const colors = {
  black: '#222222',
  lightBlack: '#606060',
  darkGray: '#929292',
  gray: '#C4C4C4',
  lightGray: '#F6F6F6',
  darkYellow: '#CCCC00',
  grayMedium: '#4a5f6d',
  green: '#34A853',
};

export default colors;
