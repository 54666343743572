import { connect } from 'react-redux';

import { RootState } from '@Store';
import RecommendationsBarChart, { RecommendationsBarChartProps } from './recommendationsBarChart';

const RecommendationsBarChartContainer = (props: RecommendationsBarChartProps) => {
  return <RecommendationsBarChart {...props} />;
};

const mapStateToProps = (state: RootState) => {
  const { client } = state;
  const { configuration } = client;
  const recommendationSheetId = configuration.recommendationSheet;
  return { recommendationSheetId };
};

export default connect(mapStateToProps)(RecommendationsBarChartContainer);
