import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { FullPageLoading } from '@Components';
import { RootState } from '@Store';

interface LandingProps {
  isFetching: boolean;
}

const Landing = ({ isFetching }: LandingProps) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (isFetching) return;

    navigate('/monitor');
  }, [isFetching]);

  return <FullPageLoading />;
};

const mapStateToProps = (state: RootState) => {
  const { authorization } = state;
  const { isFetching } = authorization;

  return { isFetching };
};

export default connect(mapStateToProps)(Landing);
