import styled, { keyframes } from 'styled-components';

import { IconClose } from '@Assets';

const dropAnimation = keyframes`
  from {
    transform: translate(-50%, -100%);
  }
  to {
    transform: translate(-50%, 0);
  }
`;

export const Container = styled.div`
  position: absolute;
  top: 30px;
  left: 50%;
  background: #ffe1e1;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  padding: 20px 15px 20px 20px;
  transform: translate(-50%, 0);
  text-align: center;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  animation: ${dropAnimation} 0.5s ease-in-out;
`;

export const StyledIconClose = styled(IconClose)`
  z-index: 1;
  cursor: pointer;
  border-radius: 2px;
  padding-bottom: 5px;

  margin-top: auto;
  margin-bottom: auto;
  path {
    stroke: #4d2818;
  }
  flex-basis: 18px;
  flex-grow: 0;
  flex-shrink: 0;
`;

export const Message = styled.div`
  font-size: 18px;
  margin-right: 15px;
  color: #662828;
  white-space: pre-line;
`;
