import { Align, BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';
import { faArrowRightLong } from '@fortawesome/free-solid-svg-icons';

import { sendCustomEvent } from '@Analytics';
import {
  ChartContainer,
  ChartOuterContainer,
  ChartSecondOuterContainer,
  Container,
  RecommendationLabel,
  RecommendationsLink,
  StyledIcon,
  TitleRecommendations,
  TotalRecommendations,
} from './recommendationsBarChart.styles';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

interface RecommendationsBarChartProps {
  labels: string[];
  values: number[];
  colors: string[];
  recommendationSheetId?: string;
}

const RecommendationsBarChart = ({ labels, values, colors, recommendationSheetId }: RecommendationsBarChartProps) => {
  const options = {
    responsive: true,
    plugins: {
      datalabels: {
        color: 'black',
        offset: -20,
        anchor: 'end' as Align,
        align: 'start' as Align,
        font: {
          weight: 700,
          size: 15,
        },
      },
      legend: { display: false },
      title: { display: false },
    },
    scales: {
      x: {
        border: { display: false },
        ticks: {
          color: '#000',
          font: { size: 15 },
        },
        grid: { display: false },
      },
      y: {
        border: { display: false },
        ticks: {
          color: '#000',
          callback(value, index) {
            return index % 2 === 0 ? value : '';
          },
        },
        grid: {
          color(context) {
            const { tick } = context;
            const { label } = tick;
            return label !== '' ? '#E5E5E5' : 'transparent';
          },
        },
      },
    },
    layout: { padding: { left: 0 } },
    maintainAspectRatio: false,
  };

  const data = {
    labels,
    datasets: [
      {
        label: 'Total Recommendations',
        data: values,
        backgroundColor: colors,
        maxBarThickness: 180,
      },
    ],
  };

  const handleNavLinkClick = () => {
    sendCustomEvent('RECOMMENDATION_LINK_CLICKED', {});
  };

  return (
    <Container>
      <TitleRecommendations>Number of Recommendations</TitleRecommendations>
      <TotalRecommendations>
        <RecommendationLabel>{values.reduce((acc, val) => acc + val, 0)}</RecommendationLabel>
        <RecommendationsLink to="/monitor" state={{ sheet: recommendationSheetId }} onClick={handleNavLinkClick}>
          Check all recommendations
          <StyledIcon icon={faArrowRightLong} />
        </RecommendationsLink>
      </TotalRecommendations>
      <ChartSecondOuterContainer>
        <ChartOuterContainer>
          <ChartContainer>
            <Bar options={options} data={data} />
          </ChartContainer>
        </ChartOuterContainer>
      </ChartSecondOuterContainer>
    </Container>
  );
};

export type { RecommendationsBarChartProps };
export default RecommendationsBarChart;
