import { useTranslation } from 'react-i18next';

import Loading from '../Loading/loading';
import { Container } from './fullPageLoading.styles';

const FullPageLoading = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Loading height={100} width={100} title={t('Global.WaitingMessage')} />
    </Container>
  );
};
export default FullPageLoading;
