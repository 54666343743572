import { useEffect, useState } from 'react';
import mime from 'mime';

import { sendCustomEvent } from '@Analytics';
import { bopsApi } from '@Network';
import S3ConfigurationFile from './s3ConfigurationFile';

interface S3ConfigurationFileContainerProps {
  label: string;
  fileType: string;
  fileName?: string;
  lastModified?: string;
  fileUpdated: () => void;
}

const S3ConfigurationFileContainer = ({
  label,
  fileType,
  fileName,
  lastModified,
  fileUpdated,
}: S3ConfigurationFileContainerProps) => {
  const [isUploading, setIsUploading] = useState(false);
  const EVENT_NAME = 'CONFIGURATION_FILE';

  const uploadToAWS = async (uploadUrl: string, selectedFile) => {
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'text/csv' },
      body: selectedFile,
    };
    const response = await fetch(uploadUrl, requestOptions);
    if (response.status !== 200) throw Error(`invalid response ${response}`);
  };

  const uploadFile = (input: any): void => {
    setIsUploading(true);
    const uploadAsync = async () => {
      const file = input.target.files[0];
      const contentType = mime.getType(file.name) || 'text/csv';
      const uploadResponse = await bopsApi.requestUploadConfigurationFile(
        fileType,
        contentType,
        file.name.split('.').pop(),
      );
      const { data } = uploadResponse;
      await uploadToAWS(data, file);
    };

    uploadAsync()
      .then(() => {
        sendCustomEvent(EVENT_NAME, { type: 'UPLOAD', fileType });
        fileUpdated();
      })
      .catch((e) => {
        // TODO: handle error
        console.log('Unable to upload file:', e);
        setIsUploading(false);
      });
  };

  useEffect(() => {
    setIsUploading(false);
  }, [lastModified]);

  const downloadFile = (): void => {
    const downloadAsync = async () => {
      const downloadResponse = await bopsApi.requestDownloadConfigurationFile(fileType);
      const { data } = downloadResponse;

      const response = await fetch(data, { method: 'GET' });
      const blob = await response.blob();

      // Create blob link to download
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName || 'data.csv');

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode?.removeChild(link);
    };

    downloadAsync()
      .then(() => {
        sendCustomEvent(EVENT_NAME, { type: 'DOWNLOAD', fileType });
      })
      .catch((e) => {
        // TODO: handle error
        console.log('Unable to download file:', e);
      });
  };

  return (
    <S3ConfigurationFile
      label={label}
      lastModified={lastModified}
      uploadFile={uploadFile}
      downloadFile={downloadFile}
      isUploading={isUploading}
    />
  );
};

export default S3ConfigurationFileContainer;
