import { Container, InnerContainer, StyledLoading, Title } from './loading.styles';

const Loading = ({
  height,
  width,
  title,
  color,
}: {
  height?: number;
  width?: number;
  title?: string;
  color?: string;
}) => {
  return (
    <Container>
      <InnerContainer>
        {title && <Title>{title}</Title>}
        <StyledLoading type="spokes" height={height} width={width} color={color || '#1A1A1A'} />
      </InnerContainer>
    </Container>
  );
};

Loading.defaultProps = {
  height: 100,
  width: 100,
  title: undefined,
};

export default Loading;
