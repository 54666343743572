import styled from 'styled-components';

/* eslint-disable import/prefer-default-export */
export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const DashboardContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;
