import { useTranslation } from 'react-i18next';

import { FileLinkCard } from '@Components';
import { Container, InnerContainer, Title, WelcomeMessage } from './configureLink.styles';

interface ConfigurationLink {
  title: string;
  description: string;
  link: string;
}

interface ConfigurationProps {
  configurationLinks: ConfigurationLink[];
}

const Configure = ({ configurationLinks }: ConfigurationProps) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Title>{t('Configuration.Title')}</Title>
      <WelcomeMessage>{t('Configuration.Message')}</WelcomeMessage>
      <InnerContainer>
        {configurationLinks?.map((configurationLink) => (
          <FileLinkCard key={configurationLink.title} {...configurationLink} />
        ))}
      </InnerContainer>
    </Container>
  );
};

export default Configure;
