import { createGlobalStyle, css } from 'styled-components';

import bopsTheme from './bopsTheme';

const commonHStyles = css`
  color: ${bopsTheme.colors.fontBlack};
  margin: 0;
`;

const GlobalStyle = createGlobalStyle`
  body {
    font-family: Inter;
    background-color : ${bopsTheme.colors.background};
  }

  button {
    font-family: Inter;
    font-weight: 600;
  }

  h1 {
    ${commonHStyles}
  }

  h2 {
    ${commonHStyles}
  }

  h3 {
    ${commonHStyles}
  }

  h4 {
    ${commonHStyles}
  }

  h5 {
    ${commonHStyles}
  }

  h6 {
    ${commonHStyles}
  }

  p {
    color: ${bopsTheme.colors.fontBlack};
    margin: 0.3125em 0;
  }
`;

export default GlobalStyle;
