import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { compose } from 'redux';

import { ClientConfiguration } from '@Models';
import { RootState } from '@Store';
import Loading from '../Loading/loading';

export type ConfigurationKey = keyof ClientConfiguration;

const withValidConfiguration = (Component, property: ConfigurationKey) => (props) => {
  const { configuration, isFetching } = props;
  const [showLoading, setShowLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (isFetching || !configuration) return;

    const valid = configuration[property];

    if (!valid) {
      navigate('/unauthorized', { replace: true });
    } else {
      setShowLoading(false);
    }
  }, [configuration, isFetching]);

  return showLoading ? <Loading /> : <Component {...props} />;
};

const mapStateToProps = (state: RootState) => {
  const { client } = state || {};
  const { configuration, isFetching } = client || {};
  return { configuration, isFetching };
};

export default compose(connect(mapStateToProps), withValidConfiguration);
