import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';

import { Container, InnerContainer, StyledIcon, Title } from './messageIcon.styles';

const MessageIcon = ({
  message,
  icon,
  iconColor,
  iconSize,
}: {
  message: string;
  icon: IconDefinition;
  iconColor?: string;
  iconSize?: SizeProp;
}) => {
  return (
    <Container>
      <InnerContainer>
        <Title>{message}</Title>
        <StyledIcon icon={icon} color={iconColor} size={iconSize} />
      </InnerContainer>
    </Container>
  );
};

MessageIcon.defaultProps = {
  iconColor: 'black',
  iconSize: '1x',
};

export default MessageIcon;
