enum ConfigurationFiles {
  ProductMaster = 'PRODUCT_MASTER',
  LocationMaster = 'LOCATION_MASTER',
  ProductLocationMaster = 'PRODUCT_LOCATION_MASTER',
  SourceDestinationLocationMaster = 'SOURCE_DESTINATION_LOCATION_MASTER',
  GlobalInventoryPolicy = 'GLOBAL_INVENTORY_POLICY',
  LevelInventoryPolicy = 'LEVEL_INVENTORY_POLICY',
}

interface ConfigurationFile {
  fileType: string;
  displayLabel: string;
  fileName?: string;
  lastModified?: string;
}

const configurationFileFromJson = (input: any): ConfigurationFile => {
  const configurationFile: ConfigurationFile = {
    fileType: input.file_type,
    displayLabel: input.display_label,
    fileName: input.file_name,
    lastModified: input.last_modified,
  };
  return configurationFile;
};

export { configurationFileFromJson, ConfigurationFiles };
export type { ConfigurationFile };
