import { useTranslation } from 'react-i18next';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

import { sendErrorEvent } from '@Analytics';
import config from '../../config';
import MessageIcon from '../MessageIcon/messageIcon';

const InvalidAccountMessage = () => {
  const { t } = useTranslation();

  const message = config.isGs1Flavor
    ? '¡Parece que tu acceso aún no se encuentra configurado!\n\nPonte en contacto con jbarrera@gs1mexico.org para completar la configuración\ny obtener acceso a tus recomendaciones de inventario.'
    : t('Global.NoAccountMessage');

  sendErrorEvent('Invalid account');

  return <MessageIcon message={message} icon={faTriangleExclamation} iconColor="#AAAA00" iconSize="3x" />;
};

export default InvalidAccountMessage;
