import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTimer } from 'react-timer-hook';

import { Permission } from '@Models';
import { AppDispatch, MiscellaneousActions, MiscellaneousKeys, MiscellaneousState, RootState } from '@Store';
import RefreshButton from './refreshPipelineButton';

interface RefreshPipelineButtonContainerProps {
  disableSeconds?: number;
  previousDisabledDate?: Date;
  defaultTooltip: string;
  updateStoreValue: (value) => void;
  setStoreNullValue: (value) => void;
  onRefreshClick: () => void;
  resetTimer: boolean;
  hasPermission: boolean;
  miscellaneousKey: string;
}

const RefreshPipelineButtonContainer = ({
  disableSeconds = 60,
  defaultTooltip,
  previousDisabledDate,
  updateStoreValue,
  setStoreNullValue,
  onRefreshClick,
  resetTimer,
  hasPermission,
  miscellaneousKey,
}: RefreshPipelineButtonContainerProps) => {
  if (!hasPermission) return null;

  const [isDisabled, setDisabled] = useState(false);

  const { seconds, minutes, restart } = useTimer({
    autoStart: false,
    expiryTimestamp: new Date(),

    onExpire: () => {
      setStoreNullValue(miscellaneousKey);
      setDisabled(false);
    },
  });

  const onClick = () => {
    setDisabled(true);

    const disabledDate = new Date();
    disabledDate.setSeconds(disabledDate.getSeconds() + disableSeconds);
    restart(disabledDate);

    const newValue: MiscellaneousState = {};
    newValue[miscellaneousKey] = disabledDate.getTime();
    updateStoreValue(newValue);

    onRefreshClick();
  };

  useEffect(() => {
    const currentDate = new Date();
    if (!previousDisabledDate || previousDisabledDate < currentDate) {
      onClick();
    } else if (previousDisabledDate > currentDate) {
      setDisabled(true);
      restart(previousDisabledDate);
    }
  }, []);

  useEffect(() => {
    if (resetTimer) {
      restart(new Date());
    }
  }, [resetTimer]);

  const tooltipText = isDisabled ? `Refreshing in ${minutes * 60 + seconds} seconds...` : defaultTooltip;

  return <RefreshButton disabled={isDisabled} onClick={onClick} tooltipText={tooltipText} />;
};

RefreshPipelineButtonContainer.defaultProps = {
  disableSeconds: 60,
  previousDisabledDate: undefined,
};

const mapStateToProps = (state: RootState, ownProps) => {
  const { miscellaneous, authorization } = state;

  const { permissions } = authorization;
  const hasPermission = permissions.includes(Permission.PipelineRefresh);

  const miscellaneousKey = 'pipelineRefresh';

  const previousDisabledDate = miscellaneous[miscellaneousKey] && new Date(miscellaneous[miscellaneousKey]);

  return { previousDisabledDate, hasPermission, miscellaneousKey };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  const { updateValue: dispatchUpdateValue, setNullValue: dispatchSetNullValue } = MiscellaneousActions;

  return {
    updateStoreValue: (value: MiscellaneousState) => dispatch(dispatchUpdateValue(value)),
    setStoreNullValue: (value: MiscellaneousKeys) => dispatch(dispatchSetNullValue(value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RefreshPipelineButtonContainer);
