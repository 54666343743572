import { useTranslation } from 'react-i18next';

import { ManualUploadCard } from '@Components';
import { Container, InnerContainer, Title, WelcomeMessage } from './manualUpload.styles';

interface ManualUploadProps {
  clients: string[];
}

const ManualUpload = ({ clients }: ManualUploadProps) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Title>{t('ManualUpload.Title')}</Title>
      <InnerContainer>
        {clients?.map((client) => (
          <ManualUploadCard key={client} clientName={client} />
        ))}
      </InnerContainer>
    </Container>
  );
};

export default ManualUpload;
