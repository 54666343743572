import OpenAiWidget, { OpenAiWidgetProps } from '../OpenAiWidget/openAiWidget.container';
import { PromptInput } from './recommendationAiWidget.styles';

interface RecommendationAiWidgetProps extends OpenAiWidgetProps {
  showPrompt: boolean;
  setPrompt: (input: string) => void;
  prompt: string;
}

const RecommendationAiWidget = ({ prompt, showPrompt, setPrompt, ...restProps }: RecommendationAiWidgetProps) => {
  return (
    <>
      {showPrompt && (
        <PromptInput value={prompt} onInput={(e) => setPrompt('value' in e.target ? String(e.target.value) : '')} />
      )}
      <OpenAiWidget {...restProps} />
    </>
  );
};

export default RecommendationAiWidget;
