import { useEffect, useRef, useState } from 'react';
import {
  createEmbeddingContext,
  DashboardExperience,
  EmbeddingEvents,
  EventListener,
  ExperienceFrameMetadata,
  MessageEventName,
} from 'amazon-quicksight-embedding-sdk';
import { connect } from 'react-redux';

import { sendCustomEvent, sendErrorEvent } from '@Analytics';
import { ErrorMessage, QuicksightTopic } from '@Components';
import { AppDispatch, MiscellaneousActions, RootState } from '@Store';
import config from '../../config';
import { Container, DashboardContainer } from './monitor.styles';

interface MonitorProps {
  dashboardUrl: string;
  sheet?: string;
  acknowledgedDashboardRefresh: () => void;
  dashboardRefresh: boolean;
  language?: string;
}

const Monitor = ({ dashboardUrl, sheet, acknowledgedDashboardRefresh, dashboardRefresh, language }: MonitorProps) => {
  const [dashboardError, setDashboardError] = useState<string>('');
  const [embeddedDashboardExperience, setEmbeddedDashboardExperience] = useState<DashboardExperience>();
  const containerDiv = useRef<HTMLInputElement>(null);

  const cleanDashboardContainerDiv = () => {
    if (containerDiv.current?.children && containerDiv.current.children.length > 0)
      while (containerDiv.current.children.length > 0)
        containerDiv.current?.removeChild(containerDiv.current.children[0]);
  };

  useEffect(() => {
    const buildDashboard = async () => {
      const onMessageHandler: EventListener = (
        event: EmbeddingEvents,
        experienceMetadata?: ExperienceFrameMetadata,
      ) => {
        switch (event.eventName) {
          case MessageEventName.ERROR_OCCURRED: {
            sendErrorEvent(`Error occurred while rendering the experience. Error: ${event.message?.errorCode}`);
            break;
          }
          case MessageEventName.PARAMETERS_CHANGED: {
            sendCustomEvent('QUICKSIGHT_PARAMETERS_CHANGED', event.message?.changedParameters);
            break;
          }
          case MessageEventName.SELECTED_SHEET_CHANGED: {
            sendCustomEvent('QUICKSIGHT_SELECTED_SHEET_CHANGED', event.message?.selectedSheet);
            break;
          }
          case MessageEventName.RESET: {
            sendCustomEvent('QUICKSIGHT_RESET');
            break;
          }
          case MessageEventName.MODAL_OPENED: {
            embeddedDashboardExperience
              ?.getSelectedSheetId()
              .then((selectedSheetId) => {
                sendCustomEvent('QUICKSIGHT_MODAL_OPENED', {
                  modalName: (event.message as any).modalName,
                  selectedSheetId,
                });
              })
              .catch((e) => {
                sendCustomEvent('QUICKSIGHT_MODAL_OPENED', {
                  modalName: (event.message as any).modalName,
                  selectedSheetId: 'Unable to get',
                });
              });
            break;
          }
          case 'CONTENT_LOADED':
          case 'SIZE_CHANGED':
            // No need to send this events to RUM
            break;

          default:
            break;
        }
      };

      const embeddingContext = await createEmbeddingContext();

      cleanDashboardContainerDiv();

      const embeddedDashboard = await embeddingContext.embedDashboard(
        {
          url: dashboardUrl,
          container: '#my-qs-frame',
          withIframePlaceholder: true,
          // onChange: (changeEvent, metadata) => { },
        },
        {
          sheetOptions: { initialSheetId: sheet },
          onMessage: onMessageHandler,
          locale: language || 'en-US',
          toolbarOptions: {
            export: true,
            undoRedo: true,
            reset: true,
            bookmarks: true,
          },
        },
      );

      setEmbeddedDashboardExperience(embeddedDashboard);
    };

    buildDashboard().catch((e) => {
      return setDashboardError(e);
    });
  }, []);

  if (dashboardError) return <ErrorMessage internalMessage={`Error loading dashboard: ${dashboardError}`} />;

  useEffect(() => {
    if (!dashboardRefresh) return;

    embeddedDashboardExperience?.reset();
    acknowledgedDashboardRefresh();
  }, [dashboardRefresh]);

  return (
    <Container>
      <QuicksightTopic />
      <DashboardContainer id="my-qs-frame" ref={containerDiv} />
    </Container>
  );
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  const { updateValue: dispatchUpdateValue } = MiscellaneousActions;

  return {
    acknowledgedDashboardRefresh: () => dispatch(dispatchUpdateValue({ dashboardRefresh: false })),
  };
};

const mapStateToProps = (state: RootState) => {
  const { miscellaneous, client } = state;
  const { dashboardRefresh } = miscellaneous;
  const { configuration } = client || {};
  const { language } = configuration || {};

  return { dashboardRefresh, language };
};

export default connect(mapStateToProps, mapDispatchToProps)(Monitor);
