import { useTranslation } from 'react-i18next';

import Button from '../Button/button';
import Loading from '../Loading/loading';
import MessagePopup from '../MessagePopup/messagePopup';
import {
  Container,
  Description,
  InputFile,
  LoadingDiv,
  Title,
  TitleContainer,
  UploadButton,
} from './fileLinkCard.styles';

interface FileLinkCardProps {
  title: string;
  link: string;
  description?: string;
  uploadFile: (input: any) => void;
  isUploading: boolean;
  errorMessage: boolean;
}

const FileLinkCard = ({ title, link, description, uploadFile, isUploading, errorMessage }: FileLinkCardProps) => {
  const { t } = useTranslation();
  const inputId = `input${title}`;

  const openFileInNewWindow = () => {
    window.open(link, '_blank');
  };

  return (
    <Container>
      {errorMessage && <MessagePopup message={t('Configuration.Error')} />}
      <TitleContainer>
        <Title>{title}</Title>
        {description && <Description>{description}</Description>}
      </TitleContainer>
      {!isUploading && (
        <>
          <Button
            onClick={openFileInNewWindow}
            buttonName="CONFIGURATION_LINK_OPENED"
            extraLogData={{ title, link }}
            height="40px"
            fontSize="0.8rem">
            {t('Configuration.Open')}
          </Button>
          <UploadButton htmlFor={inputId}>
            {t('Configuration.Replace')}
            <InputFile
              id={inputId}
              type="file"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              onChange={uploadFile}
            />
          </UploadButton>
        </>
      )}
      {isUploading && (
        <LoadingDiv>
          <Loading width={30} height={30} title={t('Configuration.Uploading')} color="#808080" />
        </LoadingDiv>
      )}
    </Container>
  );
};

export default FileLinkCard;
