import { toast } from 'react-toastify';

const showErrorToast = (message: string) => {
  return toast.error(message, {
    position: 'bottom-center',
    autoClose: 10000,
    theme: 'light',
  });
};

const showSuccessToast = (message: string) => {
  return toast.success(message, {
    position: 'bottom-center',
    autoClose: 3000,
    theme: 'light',
  });
};

export { showErrorToast, showSuccessToast };
