import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { ErrorMessage, Loading, withValidPermission } from '@Components';
import { useFetchData } from '@CustomHooks';
import { Permission, UserType } from '@Models';
import { bopsApi } from '@Network';
import { AppDispatch, RootState, usersOperations } from '@Store';
import User from './users';

interface UserProps {
  users: UserType[];
  isFetching: boolean;
  error: string;
  fetchUsers: () => void;
}
const UserContainer = ({ users, isFetching, error, fetchUsers }: UserProps) => {
  const { t } = useTranslation();

  const { data, isLoading: isLoadingClients, error: errorsClients } = useFetchData(bopsApi.getClients);

  useEffect(() => {
    if (!users && !error) fetchUsers();
  }, []);

  if ((isFetching && !users) || isLoadingClients) return <Loading title={t('User.Loading')} />;

  if (error || errorsClients || !data || !users) {
    return <ErrorMessage internalMessage={`Unable to get users: ${error}`} />;
  }

  return <User clients={data} users={users} fetchUsers={fetchUsers} />;
};

const mapStateToProps = (state: RootState) => {
  const { userList } = state;
  const { users, isFetching, error } = userList;
  return { users, isFetching, error };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  const { fetchUsers } = usersOperations;

  return {
    fetchUsers: () => dispatch(fetchUsers()),
  };
};

export default withValidPermission(connect(mapStateToProps, mapDispatchToProps)(UserContainer), Permission.ManageUsers);
