import { icon_circles, loading } from '@Assets';
import { ModalComponent } from '@Components';

interface ModalLoadingProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

const ModalLoading = ({ isOpen, onClose, children }: ModalLoadingProps) => {
  return (
    <ModalComponent isOpen={isOpen} onClose={onClose} title="" hideClose>
      <div>
        <div className="flex w-full justify-center h-full">
          <div className="relative text-center -mt-3">
            <img src={icon_circles} alt="circle" className="w-[360] text-gray-100" />
            <div className="absolute top-[24%] left-[36%]">
              <img src={loading} alt="loading" className="w-[98px] text-gray-100 ml-auto animate-spin" />
            </div>
          </div>
        </div>
        {children}
      </div>
    </ModalComponent>
  );
};

export default ModalLoading;
