import Popup from 'reactjs-popup';
import styled from 'styled-components';

export const StyledPopup = styled(Popup)`
  // use your custom style for ".popup-content"
  &-content {
    margin-left: 0px;
  }
`;

export const Container = styled.div`
  width: 100%;
  display: inline-flex;
  flex-direction: row;
  margin: auto;
`;

export const PopupContainer = styled.div`
  padding-top: 2px;
  padding-left: 4px;
`;

export const TriggerContainer = styled.div`
  min-width: 20px;
  text-align: center;
`;

export const Title = styled.h1`
  color: ${(props) => props.theme.colors.fontBlack};
  font-size: 18px;
  margin-top: 0px;
  margin-bottom: 0px;
`;

export const Menu = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
`;

export const MenuItem = styled.div`
  cursor: pointer;
  padding: 5px 5px 0px 5px;
  height: 28px;
  :not(:last-child) {
    border-bottom: 1px solid rgb(187, 187, 187);
  }
  :hover {
    color: #2980b9;
  }
`;
