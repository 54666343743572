import { S3ConfigurationFile } from '@Components';
import { ConfigurationFile, ConfigurationFiles } from '@Models';
import { Container, InnerContainer } from './configure.styles';

interface ConfigurationProps {
  configurationFiles: ConfigurationFile[];
  fileUpdated: () => void;
}

const Configure = ({ configurationFiles, fileUpdated }: ConfigurationProps) => {
  return (
    <Container>
      <InnerContainer>
        {Object.values(ConfigurationFiles).map((enumConfigurationFile) => {
          const configurationFile = configurationFiles.find((x) => x.fileType === enumConfigurationFile);
          return configurationFile ? (
            <S3ConfigurationFile
              key={configurationFile.fileType}
              label={configurationFile.displayLabel}
              fileType={configurationFile.fileType}
              fileName={configurationFile.fileName}
              lastModified={configurationFile.lastModified}
              fileUpdated={fileUpdated}
            />
          ) : null;
        })}
      </InnerContainer>
    </Container>
  );
};

export default Configure;
