import { useState } from 'react';
import mime from 'mime';

import { sendCustomEvent, sendErrorEvent } from '@Analytics';
import { bopsApi } from '@Network';
import FileLinkCard from './fileLinkCard';

interface FileLinkCardContainerProps {
  title: string;
  link: string;
  description?: string;
}

const FileLinkCardContainer = ({ title, link, description }: FileLinkCardContainerProps) => {
  const [isUploading, setIsUploading] = useState(false);
  const [errorMessage, showErrorMessage] = useState(false);

  const fileType = title.split(' ').join('_').toUpperCase();

  const uploadToAWS = async (uploadUrl: string, selectedFile, contentType) => {
    const requestOptions = {
      method: 'PUT',
      headers: { 'content-type': contentType },
      body: selectedFile,
    };
    const response = await fetch(uploadUrl, requestOptions);
    if (response.status !== 200) {
      const textResponse = await response.text();
      sendErrorEvent(`unable to upload file to AWS S3. ${textResponse}`);
      throw Error(`invalid response ${response}`);
    }
  };

  const uploadFile = (input: any): void => {
    setIsUploading(true);
    const uploadAsync = async () => {
      const file = input.target.files[0];
      const contentType = mime.getType(file.name) || 'text/csv';
      const uploadResponse = await bopsApi.requestUploadConfigurationFile(
        fileType,
        contentType,
        file.name.split('.').pop(),
      );
      const { data } = uploadResponse;
      return uploadToAWS(data, file, contentType).catch((e) => {
        setIsUploading(false);
        showErrorMessage(true);
        sendErrorEvent(`unable to upload file to AWS S3. ${e}`);
      });
    };

    uploadAsync()
      .then(() => {
        sendCustomEvent('CONFIGURATION_FILE', { type: 'UPLOAD', title });
        setIsUploading(false);
      })
      .catch((e) => {
        sendErrorEvent(`unable to request upload file. ${e}`);
        setIsUploading(false);
        showErrorMessage(true);
      });
  };

  return (
    <FileLinkCard
      uploadFile={uploadFile}
      title={title}
      link={link}
      description={description}
      isUploading={isUploading}
      errorMessage={errorMessage}
    />
  );
};

export default FileLinkCardContainer;
