import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Container = styled.div`
  height: 100vh;
  margin: 0px;
  display: flex;
  justify-content: flex-start;
`;

export const Main = styled.main`
  height: 100%;
  width: 100%;
  display: flex;
  position: relative;
  overflow-y: auto;

  flex-direction: column;

  @media ${(props) => props.theme.breakpoints.tabletLandscape} {
    flex-direction: row;
  }
`;

export const SidebarButton = styled.div`
  width: 100%;
  padding: ${(props) => `${props.theme.padding.small}px`};
  box-sizing: border-box;

  display: flex;

  @media ${(props) => props.theme.breakpoints.tabletLandscape} {
    display: none;
  }
`;

export const StyledIcon = styled(FontAwesomeIcon)`
  margin: ${(props) => `auto ${props.theme.padding.small}px auto 0px`};
`;

export const Title = styled.h2``;
