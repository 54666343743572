import styled, { css } from 'styled-components';

export const BaseButton = css<{ $height: string; $fontSize: string; $minWidth: string; disabled: boolean }>`
  border-radius: 10px;
  height: ${({ $height }) => $height};
  font-size: ${({ $fontSize }) => $fontSize};
  min-width: ${({ $minWidth }) => $minWidth};

  box-shadow: ${(props) => (props.disabled ? '' : '0px 4px 4px 0px rgba(0, 0, 0, 0.25)')};
  background-color: ${(props) => (props.disabled ? props.theme.colors.darkGray : props.theme.colors.bopsPurple)};

  &:hover {
    box-shadow: ${(props) => (props.disabled ? '' : '0px 6px 6px 0px rgba(0, 0, 0, 0.35)')};

    cursor: pointer;
    pointer-events: ${(props) => (props.disabled ? 'none' : null)};
  }
`;

export const StyledPrimaryButton = styled.button<{
  $height: string;
  $fontSize: string;
  $minWidth: string;
  disabled: boolean;
}>`
  ${BaseButton}

  border: 2px solid ${(props) =>
    props.disabled ? props.theme.colors.darkGray : props.color ?? props.theme.colors.bopsPurple};
  background-color: ${(props) =>
    props.disabled ? props.theme.colors.darkGray : props.color ?? props.theme.colors.bopsPurple};
  color: ${(props) => props.theme.colors.white};

  &:hover {
    border-color: ${(props) =>
      props.disabled ? props.theme.colors.darkGray : props.color ?? props.theme.colors.bopsPurpleHover};
    background-color: ${(props) =>
      props.disabled ? props.theme.colors.darkGray : props.color ?? props.theme.colors.bopsPurpleHover};
  }
`;

export const StyledSecondaryButton = styled.button<{
  $height: string;
  $fontSize: string;
  $minWidth: string;
  disabled: boolean;
}>`
  ${BaseButton}

  border: 2px solid ${(props) => props.theme.colors.gray};
  background-color: ${(props) =>
    props.disabled ? props.theme.colors.darkGray : props.color ?? props.theme.colors.white};
  color: ${(props) => props.theme.colors.darkGray};

  &:hover {
    background-color: ${(props) =>
      props.disabled ? props.theme.colors.darkGray : props.color ?? props.theme.colors.gray};
    background-color: ${(props) =>
      props.disabled ? props.theme.colors.darkGray : props.color ?? props.theme.colors.white};
  }
`;
