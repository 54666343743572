import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import {
  AiRecommendation,
  aiRecommendationFromJson,
  PerformanceScore,
  performanceScoreFromJson,
  Recommendation,
  recommendationFromJson,
} from '@Models';
import config from '../../config';
import { getAccessToken } from '../auth0Access';

const baseApiAddress = config.REACT_APP_BASE_API_ADDRESS;
export const networkApi = createApi({
  reducerPath: 'networkApi',
  keepUnusedDataFor: 60 * 5,
  baseQuery: fetchBaseQuery({
    baseUrl: baseApiAddress,

    prepareHeaders: async (headers) => {
      const accessToken = await getAccessToken();

      headers.set('authorization', `Bearer ${accessToken}`);

      return headers;
    },
  }),
  endpoints: (builder) => ({
    getRecommendations: builder.query<Recommendation[], void>({
      query: () => `recommendations`,
      transformResponse: (response: object[]): Recommendation[] => response.map((r) => recommendationFromJson(r)),
    }),
    getAiRecommendations: builder.query<AiRecommendation[], void>({
      query: () => `recommendations/ai?sort=recommendations_potential_value_in_usd:desc&limit=5`,
      transformResponse: (response: object[]): AiRecommendation[] => response.map((r) => aiRecommendationFromJson(r)),
    }),
    getAiRecommendationsByIds: builder.query<AiRecommendation[], string[]>({
      query: (ids: string[]) => `recommendations/ai?id=${ids.join('&id=')}`,
      transformResponse: (response: object[]): AiRecommendation[] => response.map((r) => aiRecommendationFromJson(r)),
    }),
    getPerformanceScore: builder.query<PerformanceScore[], void>({
      query: () => `recommendations/performance?sort=Timestamp:desc&limit=1`,
      transformResponse: (response: any[]): PerformanceScore[] => response.map((r) => performanceScoreFromJson(r)),
    }),
    postPipelineRuns: builder.mutation<{ error: string; pipeline_id: string }, void>({
      // TODO move this method to bopsApi
      query: () => ({ url: 'pipelineruns', method: 'POST' }),
    }),
  }),
});
export const {
  useGetRecommendationsQuery,
  usePostPipelineRunsMutation,
  useGetPerformanceScoreQuery,
  useGetAiRecommendationsQuery,
  useGetAiRecommendationsByIdsQuery,
} = networkApi;
