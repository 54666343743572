enum Permission {
  NavCredentials = 'nav:credentials',
  NavRecommendation = 'nav:recommendation',
  PipelineRefresh = 'pipeline:refresh',
  OpenAiMonitor = 'openai:monitor',
  DevOpenAiPrompt = 'dev:openai:prompt',
  MFACode = 'mfa:code',
  ManualUpload = 'internal:manualUpload',
  ManageUsers = 'manage:users',
  ManageSuppliers = 'manage:suppliers',
}

/* eslint import/prefer-default-export: "off" */
export { Permission };
