import { PropsWithChildren } from 'react';

import { sendCustomEvent } from '@Analytics';
import { StyledPrimaryButton, StyledSecondaryButton } from './button.styles';

export interface ButtonProps {
  onClick: (e: any) => void;
  buttonName: string;
  extraLogData?: object;
  primary?: boolean;
  height?: string;
  fontSize?: string;
  minWidth?: string;
  disabled?: boolean;
  color?: string;
}

const Button = ({
  onClick,
  buttonName,
  extraLogData,
  children,
  primary = true,
  height = '50px',
  fontSize = '1rem',
  minWidth = '20px',
  disabled = false,
  color,
}: PropsWithChildren<ButtonProps>) => {
  const internalOnClick = (e) => {
    sendCustomEvent('BUTTON_CLICKED', { buttonName, ...(extraLogData || {}) });
    onClick(e);
  };

  return primary ? (
    <StyledPrimaryButton
      $height={height}
      $fontSize={fontSize}
      $minWidth={minWidth}
      onClick={internalOnClick}
      disabled={disabled}
      color={color}>
      {children}
    </StyledPrimaryButton>
  ) : (
    <StyledSecondaryButton
      $height={height}
      $fontSize={fontSize}
      $minWidth={minWidth}
      onClick={internalOnClick}
      disabled={disabled}
      color={color}>
      {children}
    </StyledSecondaryButton>
  );
};

export default Button;
