import { isRejectedWithValue, Middleware, MiddlewareAPI } from '@reduxjs/toolkit';

const unauthorizedMW: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    const { payload } = action || {};
    const { originalStatus } = payload || {};

    if (originalStatus === 401) {
      window.location.replace('/');
    }
  }

  return next(action);
};

export default unauthorizedMW;
