import { forwardRef, useImperativeHandle, useState } from 'react';
import { connect } from 'react-redux';

import { Claim } from '@Models';
import { RootState } from '@Store';
import { ModalVideoCardProps } from '../ModalVideoCard/modalVideoCard';
import ModalResourceVideos from './modalResourceVideos';

type ModalResourceVideosRef = {
  showModal: () => void;
} | null;

interface ModalResourceVideosContainerProps {
  resources: ModalVideoCardProps[];
}
const ModalResourceVideosContainer = forwardRef<ModalResourceVideosRef, ModalResourceVideosContainerProps>(
  ({ resources }, ref) => {
    const [showModal, setShowModal] = useState(false);

    useImperativeHandle(ref, () => ({
      showModal() {
        setShowModal(true);
      },
    }));

    return <ModalResourceVideos show={showModal} handleClose={() => setShowModal(false)} videos={resources} />;
  },
);

const mapStateToProps = (state: RootState) => {
  const { client } = state;
  const { configuration } = client;
  const { resources } = configuration;
  return { resources };
};

export type { ModalResourceVideosRef };
export default connect(mapStateToProps, null, null, { forwardRef: true })(ModalResourceVideosContainer);
