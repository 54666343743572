import { useState } from 'react';
import { AiAssistant } from 'duckdevatgit-layer';

import { sendCustomEvent } from '@Analytics';
import { bopsTheme } from '@Themes';
import Loading from '../Loading/loading';
import {
  AiAssistantContainer,
  Container,
  LoadingContainer,
  StyledIconAi,
  StyledIconClose,
  Text,
} from './openAiAssistant.styles';

type ItemData = {
  title: string;
  subtitle: string;
  prompt: string;
  payload: string;
  content: string;
};

interface OpenAiAssistantProps {
  itemList: ItemData[];
  isLoading?: boolean;
}

const OpenAiAssistant = ({ itemList, isLoading = false }: OpenAiAssistantProps) => {
  const [showPopUp, setShowPopUp] = useState(false);

  const containerOnClick = () => {
    if (!showPopUp) {
      sendCustomEvent('OPEN_AI_WIDGET_OPENED', {});
    }

    setShowPopUp(!showPopUp);
  };

  return (
    <>
      <AiAssistantContainer>
        {!isLoading && (
          <AiAssistant
            title="bops Insight"
            placeholder="getting insights..."
            itemList={itemList}
            color={bopsTheme.colors.bopsPurple}
            image=""
            showButton={false}
            showPopUp={showPopUp}
            receiveInsights={(insights) => {
              // TODO log this
              console.log('insights:', insights);
            }}
          />
        )}
      </AiAssistantContainer>
      <Container onClick={() => containerOnClick()}>
        {isLoading ? (
          <LoadingContainer>
            <Loading width={40} height={40} color={bopsTheme.colors.white} />
          </LoadingContainer>
        ) : showPopUp ? (
          <StyledIconClose />
        ) : (
          <StyledIconAi />
        )}
        <Text>{isLoading ? 'Loading assistant' : 'Ask bops assistant'}</Text>
      </Container>
    </>
  );
};

export default OpenAiAssistant;
