import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { add_svg, icon_search } from '@Assets';
import { ButtonNew, Input, SupplierAddModal, SupplierList } from '@Components';
import { DataSupplier } from '@Models';

interface SupplierProps {
  suppliers: DataSupplier[];
  supplierUpdated: () => void;
}

const Supplier = ({ suppliers, supplierUpdated }: SupplierProps) => {
  const { t } = useTranslation();
  const [loading, setloading] = useState(false);
  const [supplierList, setSupplierList] = useState(suppliers);
  const [openAdd, setOpenAdd] = useState(false);

  useEffect(() => {
    setSupplierList(suppliers);
  }, [suppliers]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e?.target?.value;
    if (!value) {
      setSupplierList(suppliers);
      return;
    }
    const filter = suppliers.filter((supplier) => {
      const displayName = supplier.display_name.toLocaleLowerCase();
      return supplier.name.includes(value) || displayName.includes(value?.toLocaleLowerCase());
    });
    setSupplierList(filter);
  };

  return (
    <div className={`${loading ? 'pointer-events-none ' : ' '} bg-white w-full  h-full`}>
      <div className="flex flex-col p-8 bg-white md:pr-[100px] md:pl-[100px] md:pt-[20px]">
        <div className="flex">
          <div className="text-3xl font-semibold mb-6">{t('Supplier.Title')}</div>
          <div className="ml-auto">
            <ButtonNew variant="primary" isSubmit={false} onClick={() => setOpenAdd(true)}>
              <div className="flex">
                <img src={add_svg} alt="add_user" className="w-4 text-white mr-2" />
                {t('Supplier.Add')}
              </div>
            </ButtonNew>
          </div>
        </div>
        <div className="border border-gray-100 border-solid rounded-xl">
          <div className="flex md:flex-row mx-6 my-4 items-center flex-col">
            <span className="font-bold text-xl justify-start"> {`${t('Supplier.All')} (${suppliers?.length})`}</span>
            <div className="md:ml-auto mr-4">
              <div className="relative">
                <Input
                  type="text"
                  id="email"
                  onChange={handleSearch}
                  className="sm:w-32 md:w-44 text-[15px] pl-8"
                  placeholder={t('Supplier.Search')}
                />
                <div className="absolute inset-y-0 left-0 pl-2 flex items-center  pointer-events-none">
                  <img src={icon_search} alt="add_user" className="w-4 text-white mr-2" />
                </div>
              </div>
            </div>
          </div>

          <SupplierList suppliers={supplierList} />
          <SupplierAddModal
            isOpen={openAdd}
            onClose={() => setOpenAdd(false)}
            fetchSuppliers={supplierUpdated}
            setloading={setloading}
          />
        </div>
      </div>
    </div>
  );
};
export default Supplier;
