import { useRef } from 'react';
import { PopupActions, PopupPosition } from 'reactjs-popup/dist/types';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Container, Menu, MenuItem, PopupContainer, StyledPopup, Title, TriggerContainer } from './popupMenu.styles';

interface PopupMenuProps {
  title?: string;
  options: {
    id: string;
    label: string;
  }[];
  onOptionClicked(actionId: string);
  position?: PopupPosition;
}

const PopupMenu = (props: PopupMenuProps) => {
  const { title, options, position, onOptionClicked } = props;
  const ref = useRef<PopupActions>(null);
  const closeTooltip = () => ref.current?.close();

  const onMenuItemClick = (actionId) => {
    closeTooltip();
    onOptionClicked(actionId);
  };

  return (
    <Container>
      {title && <Title>Actions</Title>}
      <PopupContainer>
        <StyledPopup
          ref={ref}
          trigger={
            <TriggerContainer>
              <FontAwesomeIcon icon={faEllipsisV} color="black" />
            </TriggerContainer>
          }
          position={position}>
          <Menu>
            {options.map((action) => (
              <MenuItem
                key={action.id}
                onClick={() => {
                  onMenuItemClick(action.id);
                }}>
                {action.label}
              </MenuItem>
            ))}
          </Menu>
        </StyledPopup>
      </PopupContainer>
    </Container>
  );
};

PopupMenu.defaultProps = {
  title: null,
  position: 'bottom right',
};

export default PopupMenu;
