import { connect } from 'react-redux';

import { RequestCredentials } from '@Components';
import { RootState } from '@Store';
import MonitorContainer from './monitor.container';

interface CredentialContainerProps {
  requestCredentials?: boolean;
}

const CredentialContainer = ({ requestCredentials }: CredentialContainerProps) => {
  if (requestCredentials) return <RequestCredentials />;

  return <MonitorContainer />;
};

const mapStateToProps = (state: RootState) => {
  const { requestCredentials } = state?.client?.configuration || {};
  return { requestCredentials };
};

export default connect(mapStateToProps)(CredentialContainer);
