const screenSize = {
  mobile: 576,
  tablet: 768,
  desktopSmall: 1024,
  desktop: 1366,
  desktopLarge: 1920,
  desktopXLarge: 2560,
  autoCollapseSideBar: 868,
};

const padding = {
  small: 10,
  medium: 20,
  large: 30,
};

export { padding, screenSize };
