import { connect } from 'react-redux';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

import { AppDispatch, MiscellaneousActions, RootState } from '@Store';
import { bopsTheme } from '@Themes';
import { Container, InnerContainer, Paragraph, StyledIcon, Title } from './noAccess.styles';

const NoAccess = ({
  errorDescription,
  clearAuthenticationError,
}: {
  errorDescription?: string;
  clearAuthenticationError: () => void;
}) => {
  return (
    <Container>
      <InnerContainer>
        <StyledIcon icon={faTriangleExclamation} color={bopsTheme.colors.darkYellow} size="6x" />
        <Title>Access Request Pending</Title>
        <Paragraph>
          Thank you for your interest in <b>bops</b> platform. {errorDescription}
        </Paragraph>
        <Paragraph>
          If you havent already, please submit a request for access. Please be patient as your request is being
          reviewed. If you believe there has been an error or need urgent access, please contact the administrator.
        </Paragraph>
        <Paragraph>We appreciate your understanding.</Paragraph>
      </InnerContainer>
    </Container>
  );
};

const mapStateToProps = (state: RootState) => {
  const { miscellaneous } = state;
  const { authenticationError } = miscellaneous;

  return { errorDescription: authenticationError };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  const { updateValue: dispatchUpdateValue } = MiscellaneousActions;

  return {
    clearAuthenticationError: () => dispatch(dispatchUpdateValue({ authenticationError: '' })),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NoAccess);
