import { faCloudArrowDown, faCloudArrowUp } from '@fortawesome/free-solid-svg-icons';

import Loading from '../Loading/loading';
import {
  Container,
  DateLabel,
  DownloadButton,
  FileContainer,
  FilenameLabel,
  InputFile,
  LoadingDiv,
  StyledIcon,
  TitleContainer,
  UploadButton,
} from './s3ConfigurationFile.styles';

interface S3ConfigurationFileProps {
  label: string;
  lastModified?: string;
  uploadFile: (input: any) => void;
  downloadFile: () => void;
  isUploading: boolean;
}

const S3ConfigurationFile = ({
  label,
  lastModified,
  uploadFile,
  downloadFile,
  isUploading,
}: S3ConfigurationFileProps) => {
  const inputId = `input${label}`;

  return (
    <Container>
      <TitleContainer>
        <DateLabel>{lastModified || 'No upload date available'}</DateLabel>
        <FilenameLabel>{label}</FilenameLabel>
      </TitleContainer>
      <FileContainer>
        {!isUploading && (
          <>
            {lastModified && (
              <DownloadButton onClick={downloadFile}>
                <StyledIcon icon={faCloudArrowDown} />
                Download
              </DownloadButton>
            )}
            <UploadButton htmlFor={inputId}>
              <StyledIcon icon={faCloudArrowUp} />
              Upload
              <InputFile id={inputId} type="file" accept="text/csv" onChange={uploadFile} />
            </UploadButton>
          </>
        )}
        {isUploading && (
          <LoadingDiv>
            <Loading width={30} height={30} title="Uploading file..." color="#808080" />
          </LoadingDiv>
        )}
      </FileContainer>
    </Container>
  );
};

export default S3ConfigurationFile;
