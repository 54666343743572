import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { farmatodo_logo, kehe_logo, soriana_logo, target_logo, unfi_logo, wrl_logo } from '@Assets';
import { CredentialCardProps, ErrorMessage, Loading, withValidConfiguration } from '@Components';
import { useFetchData } from '@CustomHooks';
import { ProviderCredential } from '@Models';
import { bopsApi } from '@Network';
import { RootState } from '@Store';
import CredentialsComponent from './credentials';

const CredentialsContainer = ({ connectorTypes }: { connectorTypes: string[] }) => {
  const { t } = useTranslation();

  const { data, isLoading, error } = useFetchData(bopsApi.getCredentials, connectorTypes);

  if (isLoading) return <Loading title={t('Credentials.Loading')} />;
  if (error || !data) return <ErrorMessage internalMessage={`Unable to get credentials: ${error}`} />;

  const connector = {
    wrl: {
      icon: wrl_logo,
      name: 'Walmart Retail Link',
      showUsername: true,
      showPassword: true,
      showToken: true,
    },
    gs1_wrl: {
      icon: wrl_logo,
      name: 'Walmart Retail Link',
      showUsername: true,
      showPassword: true,
      showToken: true,
    },
    farmatodo: {
      icon: farmatodo_logo,
      name: 'Farmatodo',
      showUsername: true,
      showPassword: true,
      showAccount: true,
    },
    kehe: {
      icon: kehe_logo,
      name: 'Kehe',
      showUsername: true,
      showPassword: true,
    },
    target: {
      icon: target_logo,
      name: 'Target',
      showUsername: true,
      showPassword: true,
      showAccount: true,
    },
    unfi: {
      icon: unfi_logo,
      name: 'Unfi',
      showUsername: true,
      showPassword: true,
    },
    soriana: { icon: soriana_logo, name: 'Soriana', showToken: true },
  };

  const credentials: CredentialCardProps[] = connectorTypes
    .filter((c) => connector[c])
    .map((connectorType) => {
      const connectorCredential = data.find(
        (credential: ProviderCredential) => credential.connectorType === connectorType,
      ) || {
        connectorType,
        accountEmail: '',
        accountPassword: '',
        token: '',
        account: '',
      };
      return {
        credential: connectorCredential,
        ...connector[connectorType.toLowerCase()],
      };
    });

  return <CredentialsComponent credentials={credentials} />;
};

const mapStateToProps = (state: RootState) => {
  const { client } = state;
  const { configuration } = client;
  const { connectorTypes } = configuration || {};
  return { connectorTypes: connectorTypes || [] };
};

export default withValidConfiguration(connect(mapStateToProps)(CredentialsContainer), 'connectorTypes');
