import styled from 'styled-components';

export const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledImage = styled.img`
  width: 50px;
  border-radius: 10px;
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background: hsl(220deg 14% 97%);
  background-color: white;
  box-shadow: 0 1px 3px rgb(0 0 0/12%), 0 1px 2px rgb(0 0 0/24%);
  border: 1px solid #eee;
  border-radius: 6px;
  box-sizing: border-box;
  margin: 0;
  transition: 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55);

  padding: 10px 15px;

  @media ${(props) => props.theme.breakpoints.desktopSmall} {
    min-width: 400px;
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    padding: 15px 20px;
    min-width: 400px;
  }
  @media ${(props) => props.theme.breakpoints.desktop} {
    padding: 15px;
    min-width: 500px;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

export const Label = styled.label`
  font-weight: bold;
  margin-top: auto;
  margin-bottom: auto;
  min-width: 90px;
`;

export const Input = styled.input`
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding-right: 30px;
  width: 100%;
  box-sizing: border-box;
  margin-left: 10px;
`;

export const Button = styled.button`
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  min-width: 65px;
  box-sizing: content-box;

  &:hover {
    background-color: #0056b3;
  }
`;

export const InputWrapper = styled.div`
  position: relative;
  flex-grow: 1;
  margin-right: 10px;
`;

export const EyeIcon = styled.div`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
`;

export const ButtonsContainer = styled.div`
  margin-left: auto;
  display: flex;
  justify-content: space-between;
  gap: 20px;
`;

export const ControlWrapper = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: start;
  margin-bottom: 15px;
`;

export const Title = styled.h2`
  margin-left: 20px;
  align-self: center;
`;
