import camelcaseKeys from 'camelcase-keys';

type RecommendationType =
  | 'Important Overstock'
  | 'Overstock'
  | 'Potential Overstock'
  | 'Potential Understock'
  | 'Understock'
  | 'Potential Stockout'
  | 'Stockout';

interface Recommendation {
  id: string;
  action: string;
  efficiencyScore: number;
  locationCode: string;
  opportunityValue: number;
  productCode: string;
  productDescription: string;
  type: RecommendationType;
}

interface AiRecommendation {
  id: string;
  recommendedActionType: string;
  recommendedAction: string;
  productDescription: string;
  productCode: string;
  locationCode: string;
}

interface PerformanceScore {
  timestamp: number;
  inventoryDays: number;
  efficiencyScore: number;
  inventoryLevelScore: number;
  serviceLevelScore: number;
  ageScore: number;
  inventoryDaysScore: number;
}

const recommendationFromJson = (input: any): Recommendation => {
  const inputCased = camelcaseKeys(input);
  const recommendation: Recommendation = {
    id: String(inputCased.id),
    action: String(inputCased.action),
    efficiencyScore: Number(inputCased.efficiencyScore),
    locationCode: String(inputCased.locationCode),
    opportunityValue: Number(inputCased.opportunityValue),
    productCode: String(inputCased.productCode),
    productDescription: String(inputCased.productDescription),
    type: String(inputCased.type) as RecommendationType,
  };
  return recommendation;
};

const aiRecommendationFromJson = (input: any): AiRecommendation => {
  const inputCased = camelcaseKeys(input);
  const aiRecommendation = { ...inputCased };
  return aiRecommendation;
};

const performanceScoreFromJson = (input: any): PerformanceScore => {
  const inputCased = camelcaseKeys(input);
  const configurationFile: PerformanceScore = {
    timestamp: Date.parse(inputCased.timestamp),
    inventoryDays: Number(inputCased.inventoryDays),
    efficiencyScore: Number(inputCased.efficiencyScore),
    inventoryLevelScore: Number(inputCased.inventoryLevelScore),
    serviceLevelScore: Number(inputCased.serviceLevelScore),
    ageScore: Number(inputCased.ageScore),
    inventoryDaysScore: Number(inputCased.inventoryDaysScore),
  };
  return configurationFile;
};

export type { AiRecommendation, PerformanceScore, Recommendation, RecommendationType };
export { aiRecommendationFromJson, performanceScoreFromJson, recommendationFromJson };
