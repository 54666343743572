import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  margin: 0px;
`;

export const TableContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
`;

export const Title = styled.h1`
  color: ${(props) => props.theme.colors.fontBlack};
  font-size: 32px;
  margin-top: 0px;
  margin-bottom: 0px;
`;

export const TitleContainer = styled.div`
  display: flex;
`;

export const Table = styled.table`
  border-collapse: collapse;
`;

export const FiltersContainer = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  margin-right: 15px;
  margin-left: 9px;
`;

export const FilterContainer = styled.div``;

export const ActionContainer = styled.div`
  margin-left: auto;
  margin-top: auto;
`;

export const FilterTitle = styled.h2`
  color: ${(props) => props.theme.colors.fontBlack};
  font-size: 16px;
  margin-top: 5px;
  margin-bottom: 5px;
`;

export const NumbersCell = styled.div`
  text-align: right;
  letter-spacing: normal;
`;
