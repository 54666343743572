import { ErrorBoundary } from 'react-error-boundary';
import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faBell, faLayerGroup, faPlusCircle, faStream } from '@fortawesome/free-solid-svg-icons';

import { AuthenticationGuard, ErrorMessage, LanguageSetter, withSidebar } from '@Components';
import {
  ConfigureLink,
  Credentials,
  Documentation,
  Home,
  Landing,
  ManualUpload,
  MFACode,
  Monitor,
  NoAccess,
  NotFound,
  Recommendation,
  Suppliers,
  Unauthorized,
  Users,
} from '@Pages';
import { GlobalStyle } from '@Themes';
import { AppContainer } from './app.styles';
import './app.scss';

library.add(faBell, faLayerGroup, faPlusCircle, faStream);

const App = () => {
  const CustomErrorMessage = ({ error }) => (
    <ErrorMessage internalMessage={error || 'Unknown error catched in boundary'} />
  );

  return (
    <>
      <GlobalStyle />
      <AppContainer>
        <ToastContainer />
        <ErrorBoundary fallbackRender={CustomErrorMessage}>
          <LanguageSetter />
          <Routes>
            <Route element={<AuthenticationGuard component={withSidebar} />}>
              <Route path="recommendation" element={<Recommendation />} />
              <Route path="monitor/:monitorName?" element={<Monitor />} />
              <Route path="configure" element={<ConfigureLink />} />
              <Route path="unauthorized" element={<Unauthorized />} />
              <Route path="documentation/:pageId" element={<Documentation />} />
              <Route path="documentation" element={<Documentation />} />
              <Route path="landing" element={<Landing />} />
              <Route path="credentials" element={<Credentials />} />
              <Route path="mfa/:phoneNumber" element={<MFACode />} />
              <Route path="manualUpload" element={<ManualUpload />} />
              <Route path="users" element={<Users />} />
              <Route path="suppliers" element={<Suppliers />} />
              <Route path="*" element={<NotFound />} />
            </Route>
            <Route path="no-access" element={<NoAccess />} />
            <Route path="/" element={<Home />} />
          </Routes>
        </ErrorBoundary>
      </AppContainer>
    </>
  );
};

export default App;
