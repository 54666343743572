import { useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Row } from 'react-table';
import { showErrorToast } from '@Helpers';

import { Column, ConfirmationModal, MenuComponent, Table } from '@Components';
import { ClientObject, UserType } from '@Models';
import { bopsApi } from '@Network';
import { AppDispatch, RootState, usersOperations } from '@Store';
import config from '../../config';

const UserList = ({
  usersData,
  clientsObjects,
  setUsersDisplay,
  fetchUsers,
}: {
  usersData: UserType[];
  clientsObjects: ClientObject;
  setUsersDisplay: React.Dispatch<React.SetStateAction<UserType[]>>;
  fetchUsers: () => void;
}) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [blocked, setBlocked] = useState(false);
  const [index, setIndex] = useState(0);

  const blockorUnblockUser = () => {
    setIsModalOpen(false);
    bopsApi
      .blockUnblockUser(email, !blocked)
      .then(() => {
        const copyUsers: UserType[] = cloneDeep(usersData);
        copyUsers[index] = { ...copyUsers[index], blocked: !blocked };
        setUsersDisplay(copyUsers);
        fetchUsers();
      })
      .catch((error) => {
        showErrorToast(t('User.ErrorBlock'));
      });
  };

  const getValueCell = (row, key: string): string => {
    let cellValue = row?.original[key];
    if (key === 'app_metadata' && clientsObjects) {
      const keyClient = row?.original?.app_metadata?.account;
      cellValue = clientsObjects?.[keyClient];
    }
    if (key === 'last_login') cellValue = cellValue ? moment(cellValue).format('l') : t('User.Never');
    return cellValue;
  };

  const builderCell = (type: string, label: string, value, id: string, emailUser: string): JSX.Element => {
    if (type === 'string' || (type === 'badge' && !value)) return <div key={label}>{value}</div>;

    if (type === 'badge' && value)
      return (
        <div className="bg-gray-50 py-2 px-[10px] border-solid border-gray-100 rounded-md flex w-fit">
          <span className="text-center">{value}</span>
        </div>
      );

    if (type === 'badge-status') {
      return (
        <div
          className={`${
            value ? 'border-gray-100 bg-gray-50' : 'bg-green-100 border-green-200'
          }  px-[10px] py-[4px] border-solid rounded-md flex w-fit`}>
          <div className={`${value ? 'bg-gray-400' : 'bg-green-400'}  h-1.5 w-1.5 flex self-center rounded`} />
          <span className={`${value ? 'text-gray-400' : 'text-green-500'} ml-[6px] font-medium`}>
            {value ? t('User.Blocked') : t('User.Active')}
          </span>
        </div>
      );
    }
    return (
      <MenuComponent
        className=""
        options={[
          {
            id: 'block',
            label: value ? t('User.UnBlockLabel') : t('User.BlockLabel'),
            onAction: () => {
              setIsModalOpen(true);
              setBlocked(value);
              setIndex(+id);
              setEmail(emailUser);
            },
          },
        ]}
      />
    );
  };

  const getColumns = (): Column[] => {
    const columns = [
      { label: t('User.Email'), accessor: 'email', key: 'email', type: 'string', width: 'w-[25%]' },
      { label: t('User.Client'), accessor: 'account', key: 'app_metadata', type: 'badge', width: 'w-[30%]' },
      { label: t('User.LastLogin'), accessor: 'last_login', key: 'last_login', type: 'string', width: 'w-[20%]' },
      { label: t('User.Status'), accessor: 'status', key: 'blocked', type: 'badge-status', width: 'w-[15%]' },
      { label: '', accessor: 'block', key: 'blocked', type: 'button', width: 'w-[10%]' },
    ];

    const headers = columns.map((column) => {
      return {
        Header: column.label,
        accessor: column.accessor,
        Cell: ({ row }) => {
          const cellValue = getValueCell(row, column.key);
          return builderCell(column.type, column.label, cellValue, row.id, row?.original?.email);
        },
        width: column.width,
      };
    });
    return headers;
  };

  return (
    <>
      <Table columns={getColumns()} data={usersData} pageSize={9} pagination />

      <ConfirmationModal
        confirmText={blocked ? `${t('User.ConfirmUnBlock') + email}?` : `${t('User.ConfirmBlock') + email}?`}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={blockorUnblockUser}
      />
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  const { userList } = state;
  const { users } = userList;
  return { users };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  const { fetchUsers } = usersOperations;

  return {
    fetchUsers: () => dispatch(fetchUsers()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserList);
