import { Container } from './quicksightTopic.styles';

interface QuicksightTopicProps {
  frameId: string;
}

const QuicksightTopic = ({ frameId }: QuicksightTopicProps) => {
  return <Container id={frameId} />;
};

export default QuicksightTopic;
