import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { ClientConfiguration } from '@Models';

interface ClientState {
  error?: string;
  isFetching: boolean;
  configuration?: ClientConfiguration;
}

const initialState: ClientState = {
  error: undefined,
  isFetching: false,
  configuration: undefined,
};

const reducers = {
  fetchClientConfigurationFailure: (state: ClientState, action: PayloadAction<{ error: string }>) => {
    const { error } = action.payload || {};
    state.error = error;
    state.isFetching = false;
  },

  fetchClientConfigurationRequest: (state: ClientState) => {
    state.error = undefined;
    state.isFetching = true;
  },

  fetchClientConfigurationSuccess: (
    state: ClientState,
    action: PayloadAction<{ configuration: ClientConfiguration }>,
  ) => {
    const { configuration } = action.payload || {};
    state.configuration = configuration;
    state.isFetching = false;
  },
};

const slice = createSlice({ initialState, name: 'client', reducers });

export const { actions } = slice;
export default slice.reducer;
