import { useState } from 'react';
import mime from 'mime';

import { sendCustomEvent, sendErrorEvent } from '@Analytics';
import { bopsApi } from '@Network';
import ManualUploadCard from './manualUploadCard';

interface ManualUploadCardContainerProps {
  clientName: string;
}

const ManualUploadCardContainer = ({ clientName }: ManualUploadCardContainerProps) => {
  const [isUploading, setIsUploading] = useState(false);
  const [errorMessage, showErrorMessage] = useState(false);

  const uploadToAWS = async (uploadUrl: string, file, contentType) => {
    const requestOptions = {
      method: 'PUT',
      headers: { 'content-type': contentType },
      body: file,
    };

    const response = await fetch(uploadUrl, requestOptions);
    if (response.status !== 200) {
      const textResponse = await response.text();
      sendErrorEvent(`unable to upload file to AWS S3. ${textResponse}`);
      throw Error(`invalid response ${response}`);
    }

    sendCustomEvent('MANUAL_FILE', { type: 'UPLOADED', clientName, fileName: file.name });
    await bopsApi.reportFileUploaded(clientName, file.name, contentType);
  };

  const uploadFiles = (input: any): void => {
    setIsUploading(true);
    const uploadAsync = async () => {
      const { files } = input.target;
      const filesList = Array.from(files);

      await Promise.all(
        filesList.map(async (file: any) => {
          const fileName = file.name;
          const contentType = mime.getType(fileName) || 'text/csv';
          const link = await bopsApi.buildUploadLink(clientName, fileName, contentType);

          sendCustomEvent('MANUAL_FILE', { type: 'UPLOADING', clientName, fileName });

          return uploadToAWS(link, file, contentType).catch((e) => {
            setIsUploading(false);
            showErrorMessage(true);
            sendErrorEvent(`unable to manual upload file to AWS S3. ${e}`);
          });
        }),
      );
    };

    uploadAsync()
      .then(() => {
        setIsUploading(false);
      })
      .catch((e) => {
        sendErrorEvent(`unable to request manual upload file. ${e}`);
        setIsUploading(false);
        showErrorMessage(true);
      });
  };

  return (
    <ManualUploadCard
      uploadFiles={uploadFiles}
      title={clientName}
      isUploading={isUploading}
      errorMessage={errorMessage}
    />
  );
};

export default ManualUploadCardContainer;
