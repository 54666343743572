import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;

  padding: ${(props) => `0px ${props.theme.padding.small}px`};

  @media ${(props) => props.theme.breakpoints.tabletLandscape} {
    padding: ${(props) => `${props.theme.padding.small}px`};
  }

  @media ${(props) => props.theme.breakpoints.desktopSmall} {
    padding: ${(props) => `${props.theme.padding.medium}px`};
  }

  @media ${(props) => props.theme.breakpoints.desktopLarge} {
    padding: ${(props) => `${props.theme.padding.large}px`};
  }
`;

export const Title = styled.h1`
  font-weight: 1000;
  line-height: 1;

  display: none;

  @media ${(props) => props.theme.breakpoints.tabletLandscape} {
    display: flex;
  }
`;

export const WelcomeMessage = styled.p`
  color: #6d6969;
  margin-top: 0px;

  @media ${(props) => props.theme.breakpoints.tabletLandscape} {
    margin-top: ${(props) => `${props.theme.padding.small}px`};
  }
`;

export const InnerContainer = styled.div`
  margin: 10px 30px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 30px;

  padding: ${(props) => `${props.theme.padding.small}px 0px`};

  @media ${(props) => props.theme.breakpoints.desktopSmall} {
    padding: ${(props) => `${props.theme.padding.medium}px 0px`};
    flex-direction: column;
    max-width: 600px;
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    gap: 30px;
  }

  @media ${(props) => props.theme.breakpoints.desktopLarge} {
    padding-top: ${(props) => `${props.theme.padding.large}px`};
    margin: 10px 40px;
    gap: 20px;
  }
`;
