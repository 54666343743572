enum Claim {
  Account = 'https://wwww.bops.ai/account',
  UserDisplayName = 'https://wwww.bops.ai/user_display_name',
  Email = 'https://wwww.bops.ai/email',
  GS1Email = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name',
  ManualUpload = 'https://wwww.bops.ai/manualUpload',
}

/* eslint import/prefer-default-export: "off" */
export { Claim };
