import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import runningOnProd from './runningOnProd';

const initializeSentry = () => {
  if (!runningOnProd()) return;

  Sentry.init({
    dsn: 'https://223d22ab806a4c20a41434b56f40c200@o1108805.ingest.sentry.io/6301402',
    integrations: [new BrowserTracing()],
    environment: 'dev',
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
};

export default initializeSentry;
