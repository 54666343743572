import { ColumnInstance, TableToggleHideAllColumnProps } from 'react-table';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faColumns } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useComponentVisible } from '@CustomHooks';
import {
  ColumnIcon,
  Container,
  Div,
  DropdownContainer,
  Icon,
  LauncherTitle,
  ListItem,
  ListItemInput,
  ListItemLabel,
  TopContainer,
} from './columnPicker.styles';

export interface ColumnPickerProps {
  allColumns: ColumnInstance<object>[];
  getToggleHideAllColumnsProps: (
    props?: Partial<TableToggleHideAllColumnProps> | undefined,
  ) => TableToggleHideAllColumnProps;
}

const ColumnPicker = (props: ColumnPickerProps) => {
  const { allColumns, getToggleHideAllColumnsProps } = props;
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  const onClick = () => {
    const updateActive = !isComponentVisible;
    setIsComponentVisible(updateActive);
  };

  return (
    <Container ref={ref}>
      <ColumnIcon>
        <Icon active={isComponentVisible} onClick={onClick}>
          <FontAwesomeIcon icon={faColumns} size="lg" />
        </Icon>
      </ColumnIcon>
      {isComponentVisible && (
        <DropdownContainer>
          <Div>
            <TopContainer>
              <LauncherTitle>Filter Columns</LauncherTitle>
            </TopContainer>
          </Div>
          {/* TODO: enable Toggle All when we have default columns that can't be toggled out.
          <Div>
            <IndeterminateCheckbox {...getToggleHideAllColumnsProps()} /> Toggle All
          </Div> */}
          {allColumns.map((column) => {
            const displayHeader =
              typeof column.render('Header') === 'string' || column.render('Header') instanceof String;
            return (
              displayHeader && (
                <ListItem key={column.id}>
                  <ListItemLabel htmlFor="a">
                    <ListItemInput type="checkbox" {...column.getToggleHiddenProps()} /> {column.render('Header')}
                  </ListItemLabel>
                </ListItem>
              )
            );
          })}
        </DropdownContainer>
      )}
    </Container>
  );
};

ColumnPicker.defaultProps = {};

export default ColumnPicker;
