import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { StyledPrimaryButton } from '../Button/button.styles';
import { InnerContainer, Title as LoadingTitle } from '../Loading/loading.styles';

export const Container = styled.div`
  display: flex;
  align-items: stretch;
  border-radius: 0.8rem;
  min-height: 80px;
  width: 100%;
  max-width: 750px;
  margin: 0 auto 0 0;
  box-shadow: 0 1px 3px rgb(0 0 0/12%), 0 1px 2px rgb(0 0 0/24%);
  background: hsl(220deg 14% 97%);
  background-color: white;
  border: 1px solid #eee;
  transition: 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55);
  color: black;
  padding: 0px 20px;
  box-sizing: border-box;

  ${StyledPrimaryButton} {
    margin: auto 0px;
    min-width: 70px;
  }
`;
export const InputFile = styled.input``;

export const Title = styled.h4`
  margin: auto 0px;
  font-size: 20px;
  box-sizing: border-box;
  color: #333;
`;
export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0;
`;

export const UploadButton = styled.label`
  padding: 6px 12px;
  cursor: pointer;
  border-radius: 10px;
  margin: auto 0px auto 10px;
  font-size: 0.8rem;
  font-weight: 600;
  width: auto;
  height: 40px;
  box-sizing: border-box;
  display: flex;
  align-items: center;

  box-shadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)';

  border: 2px solid ${(props) => props.theme.colors.bopsPurple};
  background-color: ${(props) => props.theme.colors.bopsPurple};
  color: ${(props) => props.theme.colors.white};

  &:hover {
    border-color: ${(props) => props.theme.colors.bopsPurpleHover};
    background-color: ${(props) => props.theme.colors.bopsPurpleHover};
    box-shadow: '0px 6px 6px 0px rgba(0, 0, 0, 0.35)';

    cursor: pointer;
    pointer-events: null;
  }

  input {
    display: none;
  }
`;

export const StyledIcon = styled(FontAwesomeIcon).attrs(() => ({
  size: '1x',
  color: '#808080',
}))`
  margin-right: 10px;
`;

export const LoadingDiv = styled.div`
  ${LoadingTitle} {
    margin: auto 20px auto 0px;
    font-size: 0.9em;
  }

  ${InnerContainer} {
    display: flex;
    flex-direction: row;
  }
`;
