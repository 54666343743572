import styled from 'styled-components';

/* eslint import/prefer-default-export: "off" */

export const Container = styled.div<{ isActive: boolean }>`
  height: 41px;
  width: 41px;
  cursor: pointer;
  color: white;
  transition-duration: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  margin-right: 4px;

  color: ${({ isActive, theme }) => `${isActive ? theme.colors.bopsPurple : theme.colors.white}`};

  :hover {
    color: ${({ isActive, theme }) =>
      `${isActive ? theme.colors.bopsPurpleActive : theme.colors.transparentLightGrey}`};
  }
`;
