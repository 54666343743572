/* eslint-disable import/prefer-default-export */
export const snakeCaseKeys = (obj: object): object => {
  if (typeof obj !== 'object') return obj;

  Object.keys(obj).forEach((oldName) => {
    // Camel to underscore
    const newName = oldName.replace(/([A-Z])/g, ($1) => {
      return `_${$1.toLowerCase()}`;
    });

    // Only process if names are different
    if (newName !== oldName) {
      // Check for the old property name to avoid a ReferenceError in strict mode.
      if (Object.prototype.hasOwnProperty.call(obj, oldName)) {
        /* eslint-disable no-param-reassign */
        obj[newName] = obj[oldName];
        delete obj[oldName];
      }
    }

    // Recursion
    if (typeof obj[newName] === 'object') {
      obj[newName] = snakeCaseKeys(obj[newName]);
    }
  });

  return obj;
};
