import { useTranslation } from 'react-i18next';

import { CredentialCard, CredentialCardProps } from '@Components';
import { Container, InnerContainer, Title, WelcomeMessage } from './credentials.styles';

interface CredentialsProps {
  credentials: CredentialCardProps[];
}

const Credentials = ({ credentials }: CredentialsProps) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Title>{t('Credentials.Title')}</Title>
      <WelcomeMessage>{t('Credentials.Subtitle')}</WelcomeMessage>
      <InnerContainer>
        {credentials?.map((credential) => (
          <CredentialCard {...credential} key={credential.credential.connectorType} />
        ))}
      </InnerContainer>
    </Container>
  );
};

export default Credentials;
