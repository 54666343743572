import { Tooltip as ReactTooltip } from 'react-tooltip';
import { faArrowsRotate } from '@fortawesome/free-solid-svg-icons';

import { Container, OutsideContainer, StyledIcon } from './refreshPipelineButton.styles';

interface RefreshPipelineButtonProps {
  onClick: () => void;
  disabled: boolean;
  tooltipText: string;
}
const RefreshPipelineButton = ({ onClick, disabled, tooltipText }: RefreshPipelineButtonProps) => {
  const tooltipId = 'refresh-tooltip';
  return (
    <OutsideContainer data-tooltip-id={tooltipId}>
      <Container $disabled={disabled} onClick={onClick}>
        <StyledIcon icon={faArrowsRotate} />
      </Container>
      <ReactTooltip id={tooltipId} positionStrategy="fixed" place="left" content={tooltipText} />
    </OutsideContainer>
  );
};

export default RefreshPipelineButton;
