import { JwtPayload } from 'jwt-decode';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { Claim, Permission } from '@Models';

interface AuthorizationState {
  error?: string;
  isFetching: boolean;
  permissions: Permission[];
  claims?: Record<Claim, string | null>;
}

const initialState: AuthorizationState = {
  error: undefined,
  isFetching: false,
  permissions: [],
  claims: undefined,
};

const reducers = {
  fetchUserAccessFailure: (state: AuthorizationState, action: PayloadAction<{ error: string }>) => {
    const { error } = action.payload || {};
    state.error = error;
    state.isFetching = false;
  },

  fetchUserAccessRequest: (state: AuthorizationState) => {
    state.error = undefined;
    state.isFetching = true;
  },

  fetchUserAccessSuccess: (state: AuthorizationState, action: PayloadAction<{ tokenPayload: JwtPayload }>) => {
    const { tokenPayload } = action.payload || {};
    const { permissions } = tokenPayload;
    state.claims = <Record<Claim, string | null>>{};

    Object.values(Claim).forEach((claim) => {
      if (state.claims) state.claims[claim] = claim in tokenPayload ? tokenPayload[claim] : null;
    });

    state.isFetching = false;
    state.permissions = permissions;
  },
};

const slice = createSlice({ initialState, name: 'authorization', reducers });

export const { actions } = slice;
export default slice.reducer;
