import { useNavigate } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';

import config from '../config';

const Auth0WithHistoryProvider = ({ children }) => {
  const navigate = useNavigate();

  const domain = config.REACT_APP_AUTH0_DOMAIN;
  const clientId = config.REACT_APP_AUTH0_CLIENT_ID;
  const redirectUri = config.REACT_APP_AUTH0_CALLBACK_URL;
  const audience = config.REACT_APP_AUTH0_AUDIENCE;

  const onRedirectCallback = (appState) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  if (!(domain && clientId)) {
    return null;
  }

  return (
    <Auth0Provider
      audience={audience}
      domain={domain}
      clientId={clientId}
      redirectUri={redirectUri}
      onRedirectCallback={onRedirectCallback}>
      {children}
    </Auth0Provider>
  );
};

export default Auth0WithHistoryProvider;
