import { useEffect } from 'react';

import { check_circle, icon_circles } from '@Assets';
import { ModalComponent } from '@Components';

interface ModalSuccessProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

const ModalSuccess = ({ isOpen, onClose, children }: ModalSuccessProps) => {
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      onClose();
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [isOpen]);

  return (
    <ModalComponent isOpen={isOpen} onClose={onClose} title="">
      <div>
        <div className="flex w-full justify-center h-full">
          <div className="relative -mt-3">
            <img src={icon_circles} alt="close_user" className="w-[360px] text-gray-100 ml-auto" />
            <div className="bg-primary left-[40%] top-[30%] p-[15px] flex flex-col rounded-md absolute">
              <img src={check_circle} alt="close_user" className="w-[40px] text-gray-100 ml-auto" />
            </div>
          </div>
        </div>
        {children}
      </div>
    </ModalComponent>
  );
};

export default ModalSuccess;
