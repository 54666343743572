import { useTranslation } from 'react-i18next';

import { ErrorMessage, Loading, withValidConfiguration } from '@Components';
import { useFetchData } from '@CustomHooks';
import { ConfigurationFile, Permission } from '@Models';
import { bopsApi } from '@Network';
import Configure from './configure';

const ConfigureContainer = () => {
  const { t } = useTranslation();

  const { data, isLoading, error, refetch } = useFetchData(bopsApi.getFilesConfiguration);

  if (isLoading) return <Loading title={t('Configuration.Loading')} />;

  if (error || !data) return <ErrorMessage internalMessage={`Unable to get configuration messages ${error}`} />;

  const fileUpdated = () => {
    refetch();
  };

  return <Configure configurationFiles={data} fileUpdated={fileUpdated} />;
};

export default withValidConfiguration(ConfigureContainer, 'configurationLinks');
