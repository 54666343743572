import styled from 'styled-components';

import { IconClose } from '@Assets';

export const Container = styled.div`
  border: 1px solid ${(props) => props.theme.colors.gray};
  border-radius: 10px;
  background-color: white;
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-basis: 0;
`;

export const StyledImage = styled.img`
  cursor: pointer;
  max-width: 400px;
  max-height: 200px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom: 1px solid ${(props) => props.theme.colors.lightGray};

  @media ${(props) => props.theme.breakpoints.mobile} {
    max-width: 275px;
  }
`;

export const Title = styled.h4`
  min-height: 50px;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-grow: 1;
`;

export const StyledIconClose = styled(IconClose)`
  z-index: 1;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 2px;

  path {
    stroke: ${(props) => props.theme.colors.lightBlack};
  }

  width: 32px;
  height: 32px;

  @media ${(props) => props.theme.breakpoints.mobile} {
    width: 24px;
    height: 24px;
  }
`;

export const InnerContainer = styled.div`
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FrameContainer = styled.div`
  position: relative;
  width: 90%;
  height: 90%;
  box-shadow: 0px 10px 40px rgba(255, 255, 255, 0.2);
  max-width: 90%;
  max-height: 90%;
  overflow: auto;
  border: 2px solid ${(props) => props.theme.colors.darkGray};
`;

export const StyledIFrame = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
