import { useTranslation } from 'react-i18next';

import {
  RecommendationAiWidget,
  RecommendationCard,
  RecommendationCardProps,
  RecommendationsBarChart,
  StatusIndicator,
  StatusIndicatorProps,
} from '@Components';
import {
  Container,
  ContentTitle,
  InnerContainer,
  LeftContainer,
  RightContainer,
  SpanBold,
  StatusIndicatorContainer,
  Title,
  WelcomeMessage,
} from './recommendation.styles';

interface RecommendationProps {
  statusIndicators: StatusIndicatorProps[];
  recommendations: RecommendationCardProps[];
  recommendationsChart: {
    labels: string[];
    values: number[];
    colors: string[];
  };
  userName?: string;
  recommendationsId: string[];
}

const Recommendation = ({
  recommendations,
  statusIndicators,
  recommendationsChart,
  userName,
  recommendationsId,
}: RecommendationProps) => {
  const { t } = useTranslation();

  const salute = () =>
    userName ? (
      <>
        {t('Recommendation.WelcomeMessageHi')} <SpanBold>{userName}</SpanBold>, {t('Recommendation.WelcomeMessageHere')}
      </>
    ) : (
      <>{t('Recommendation.WelcomeMessageHere')}</>
    );

  return (
    <Container>
      <Title>{t('Recommendation.Dashboard')}</Title>
      <WelcomeMessage>
        {salute()}
        {t('Recommendation.WelcomeMessageFinal', { length: recommendations.length })}
      </WelcomeMessage>
      <InnerContainer>
        <LeftContainer>
          <StatusIndicatorContainer>
            {statusIndicators.map((statusIndicator) => (
              <StatusIndicator {...statusIndicator} key={statusIndicator.title} />
            ))}
          </StatusIndicatorContainer>
          <RecommendationsBarChart
            labels={recommendationsChart.labels}
            values={recommendationsChart.values}
            colors={recommendationsChart.colors}
          />
        </LeftContainer>
        <RightContainer>
          <ContentTitle>{t('Recommendation.TopRecommendations', { length: recommendations.length })}</ContentTitle>
          {recommendations.map((recommendation) => (
            <RecommendationCard {...recommendation} key={recommendation.locationCode + recommendation.productCode} />
          ))}
        </RightContainer>
      </InnerContainer>
      <RecommendationAiWidget />
    </Container>
  );
};

export default Recommendation;
