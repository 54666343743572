import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const OutsideContainer = styled.div`
  position: fixed;
  width: 50px;
  height: 50px;
  bottom: 58px;
  right: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: pre-line;
`;

export const Container = styled.div<{ $disabled: boolean }>`
  width: 50px;
  height: 50px;
  border-radius: 72px;
  background-color: ${(props) => props.theme.colors.bopsPurple};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.5s ease-in-out;
  :hover {
    transform: rotate(180deg);
  }
  cursor: pointer;

  ${({ $disabled }) =>
    $disabled &&
    `
    pointer-events: none;
    background-color:grey;
  `}
`;

export const StyledIcon = styled(FontAwesomeIcon).attrs(() => ({
  size: '1x',
  color: 'white',
}))`
  font-size: 1.5em;
`;
