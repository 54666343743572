import styled, { css } from 'styled-components';

import { IconAi, IconClose } from '@Assets';

export const Container = styled.div`
  color: '#1A1A1A';
  background-color: '#1A1A1A';

  position: fixed;
  display: flex;
  border-radius: 50px;
  align-items: center;
  justify-content: end;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.5);
  cursor: pointer;

  height: 54px;
  width: 255px;
  bottom: 35px;
  right: 30px;

  @media ${(props) => props.theme.breakpoints.mobile} {
    height: 44px;
    width: 230px;
    bottom: 13px;
    right: 10px;
  }
`;

export const AiAssistantContainer = styled.div`
  z-index: 1;

  .text-main-style {
    font-family: Inter;
  }

  .subtitle-text-style {
    text-align: left;
  }

  .ai-assistant-main-container {
    bottom: 35px;
    right: 225px;

    @media ${(props) => props.theme.breakpoints.mobile} {
      bottom: 15px;
      right: 185px;
    }

    .main-popup-button {
      box-shadow: none;
      height: 54px;
      width: 50px;

      @media ${(props) => props.theme.breakpoints.mobile} {
        height: 40px;
        width: 40px;
      }
    }

    .main-popup-container-animate-start {
      bottom: 60px;
      right: -200px;

      @media ${(props) => props.theme.breakpoints.mobile} {
        max-width: 280px;
        bottom: 50px;
        right: -170px;
      }
    }

    .main-popup-container-animate-end {
      bottom: 60px;
      right: -200px;

      @media ${(props) => props.theme.breakpoints.mobile} {
        max-width: 280px;
        bottom: 50px;
        right: -170px;
      }
    }
  }
`;

export const LoadingContainer = styled.div`
  margin: 0px auto;
  padding-left: 18px;
  width: auto;
`;

export const Text = styled.h3`
  color: ${(props) => props.theme.colors.white};
  padding-right: 20px;
  padding-left: 12px;
  cursor: pointer;
  height: 100%;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  display: flex;
  align-items: center;

  @media ${(props) => props.theme.breakpoints.mobile} {
    font-size: 16px;
  }
`;

const iconStyle = css`
  width: 32px;
  height: 32px;
  @media ${(props) => props.theme.breakpoints.mobile} {
    width: 24px;
    height: 24px;
  }
`;

export const StyledIconAi = styled(IconAi)`
  ${iconStyle}
`;

export const StyledIconClose = styled(IconClose)`
  ${iconStyle}
`;
