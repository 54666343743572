import styled from 'styled-components';

export const Container = styled.div<{ $isFetching: boolean }>`
  display: block;
  width: 100%;
  position: relative;
  ${({ $isFetching }) => $isFetching && 'overflow:hidden;'}
`;

export const FetchingContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.5);
`;

export const NotionContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  overflow: hidden;

  .breadcrumbs {
    span {
      display: flex;
      span {
        align-items: center;
      }
    }
  }
`;
