import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { compose } from 'redux';

import { Permission } from '@Models';
import { RootState } from '@Store';
import Loading from '../Loading/loading';

const withValidPermission = (Component, permission: Permission) => (props) => {
  const { permissions, isFetching } = props;
  const [showLoading, setShowLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (isFetching) return;

    const isPermissionValid = Array.isArray(permissions) && permissions.includes(permission);

    if (!isPermissionValid) {
      navigate('/notfound', { replace: true });
    } else {
      setShowLoading(false);
    }
  }, [permissions, isFetching]);

  return showLoading ? <Loading /> : <Component {...props} />;
};

const mapStateToProps = (state: RootState) => {
  const { authorization } = state;
  const { permissions, isFetching } = authorization;
  return { permissions, isFetching };
};

export default compose(connect(mapStateToProps), withValidPermission);
