import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { RootState } from '@Store';

const LanguageSetter = ({ language }: { language?: string }) => {
  const { i18n } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  return null;
};

const mapStateToProps = (state: RootState) => {
  const { client } = state;
  const { configuration } = client || {};
  const { language } = configuration || {};

  return { language };
};

export default connect(mapStateToProps)(LanguageSetter);
