import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { RootState } from '@Store';
import Documentation from './documentation';

interface ToolkitContainerProps {
  documentationId: string;
}

const DocumentationContainer = ({ documentationId }: ToolkitContainerProps) => {
  const params = useParams();
  const { pageId } = params;
  const navigate = useNavigate();

  useEffect(() => {
    if (!pageId) {
      navigate(`/documentation/${documentationId}`);
    }
  }, []);

  if (!pageId) return null;

  return <Documentation pageId={pageId} />;
};

const mapStateToProps = (state: RootState) => {
  const { client } = state;
  const { configuration } = client || {};
  const { dataRepository } = configuration || {};
  const documentationId = dataRepository || '';
  return { documentationId };
};

export default connect(mapStateToProps)(DocumentationContainer);
