import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;

  padding: ${(props) => `0px ${props.theme.padding.small}px`};

  @media ${(props) => props.theme.breakpoints.tabletLandscape} {
    padding: ${(props) => `${props.theme.padding.small}px`};
  }

  @media ${(props) => props.theme.breakpoints.desktopSmall} {
    padding: ${(props) => `${props.theme.padding.medium}px`};
  }

  @media ${(props) => props.theme.breakpoints.desktopLarge} {
    padding: ${(props) => `${props.theme.padding.large}px`};
  }
`;

export const Title = styled.h1`
  font-weight: 1000;
  line-height: 1;

  display: none;

  @media ${(props) => props.theme.breakpoints.tabletLandscape} {
    display: flex;
  }
`;

export const ContentTitle = styled.h3`
  margin: ${(props) => `${props.theme.padding.small * 2}px auto ${props.theme.padding.small * 2}px auto`};

  @media ${(props) => props.theme.breakpoints.desktopSmall} {
    display: none;
  }
`;

export const WelcomeMessage = styled.p`
  color: #6d6969;
  margin-top: 0px;

  @media ${(props) => props.theme.breakpoints.tabletLandscape} {
    margin-top: ${(props) => `${props.theme.padding.small}px`};
  }
`;

export const SpanBold = styled.span`
  font-weight: 600;
`;

export const InnerContainer = styled.div`
  margin: 0px;
  display: flex;
  flex-direction: column;

  padding: ${(props) => `${props.theme.padding.small}px 0px`};

  @media ${(props) => props.theme.breakpoints.desktopSmall} {
    padding: ${(props) => `${props.theme.padding.medium}px 0px`};
  }

  @media ${(props) => props.theme.breakpoints.desktopSmall} {
    flex-direction: row;
  }

  @media ${(props) => props.theme.breakpoints.desktopLarge} {
    padding-top: ${(props) => `${props.theme.padding.large}px`};
  }
`;

export const LeftContainer = styled.div`
  box-sizing: border-box;
  padding: 0px;
  width: 100%;

  display: flex;
  flex-direction: column;

  @media ${(props) => props.theme.breakpoints.desktopSmall} {
    flex: 1;
    margin-right: ${(props) => `${props.theme.padding.medium / 2}px`};
  }

  @media ${(props) => props.theme.breakpoints.desktopLarge} {
    margin-right: ${(props) => `${props.theme.padding.large / 2}px`};
  }
`;

export const StatusIndicatorContainer = styled.div`
  display: flex;

  margin: ${(props) => `0px 0px ${props.theme.padding.small}px 0px`};

  > *:not(:last-child) {
    margin-right: ${(props) => `${props.theme.padding.small / 2}px`};
  }
  > *:not(:first-child) {
    margin-left: ${(props) => `${props.theme.padding.small / 2}px`};
  }

  @media ${(props) => props.theme.breakpoints.desktopLarge} {
    margin-bottom: ${(props) => `${props.theme.padding.medium}px`};

    > *:not(:last-child) {
      margin-right: ${(props) => `${props.theme.padding.medium / 2}px`};
    }

    > *:not(:first-child) {
      margin-left: ${(props) => `${props.theme.padding.medium / 2}px`};
    }
  }
`;

export const RightContainer = styled.div`
  > *:not(:last-child) {
    margin-bottom: ${(props) => `${props.theme.padding.small}px`};
  }

  @media ${(props) => props.theme.breakpoints.desktopSmall} {
    flex: 1;
    margin-left: ${(props) => `${props.theme.padding.medium / 2}px`};
  }

  @media ${(props) => props.theme.breakpoints.desktopLarge} {
    margin-left: ${(props) => `${props.theme.padding.large / 2}px`};

    > *:not(:last-child) {
      margin-bottom: ${(props) => `${props.theme.padding.medium}px`};
    }
  }
`;
