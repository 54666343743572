import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Menu, useProSidebar } from 'react-pro-sidebar';
import { matchPath, useLocation } from 'react-router-dom';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { useWindowSize } from 'usehooks-ts';
import { faAnglesLeft, faScrewdriverWrench, faSignOut } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  BopsLogoExpanded,
  BopsTitleLogo,
  ClientLogoIscpg,
  gs1_mexico_logo,
  gs1_mexico_logo_sm,
  IconArchive,
} from '@Assets';
import { Permission } from '@Models';
import { screenSize } from '@Themes';
import config from '../../config';
import ModalResourceVideos, { ModalResourceVideosRef } from '../ModalResourceVideos/modalResourceVideos.container';
import { ConfigurationKey } from '../WithValidConfiguration/withValidConfiguration';
import {
  Anchor,
  Content,
  Footer,
  Header,
  HeaderLogo,
  LogoutContainer,
  PageLinkContainer,
  Separator,
  SidebarContainer,
  SidebarLink,
  SideBarText,
  StyledExtraButton,
  StyledMenuItem,
  StyledNavMenuItem,
  StyledSidebar,
  StyledSubMenu,
} from './sidebar.styles';

export interface MenuItemProps {
  permission?: Permission;
  configurationKey?: ConfigurationKey;
  label: string;
  icon: JSX.Element;
  link: string;
  enabled?: boolean;
  externalLink?: string;
  extraIcon?: JSX.Element;
  extraIconTooltip?: string;
  extraIconOnClick?: () => void;
}

interface SidebarProps {
  menuItems: MenuItemProps[];
  onLogout: () => void;
  showResources: boolean;
  dataRepository?: string;
  account: string;
  logo?: string;
}

const Sidebar = ({ menuItems, onLogout, showResources, dataRepository, account, logo }: SidebarProps) => {
  const { collapseSidebar, collapsed } = useProSidebar();
  const { width } = useWindowSize();
  const modalResourceRef = useRef<ModalResourceVideosRef>(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (width > 0 && width < screenSize.autoCollapseSideBar) {
      collapseSidebar(true);
    }
  }, [width]);

  const buildTooltip = ({ id, content }: { id: string; content: string }) => {
    return collapsed && <ReactTooltip id={id} positionStrategy="fixed" place="right" content={content} offset={18} />;
  };

  const buildMenuItem = ({
    label,
    icon,
    link,
    enabled,
    externalLink,
    extraIcon,
    extraIconTooltip,
    extraIconOnClick,
  }: MenuItemProps) => {
    const isPathActive = !!matchPath(decodeURIComponent(useLocation().pathname), link || '');
    const tooltipId = `tooltip-${label}`;

    return (
      <StyledNavMenuItem key={label} $collapsed={collapsed} $isPathActive={isPathActive}>
        {externalLink ? (
          <PageLinkContainer data-tooltip-id={tooltipId}>
            <Anchor href={externalLink} target="_blank" rel="noreferrer">
              {icon}
              <SideBarText $collapsed={collapsed}>{label}</SideBarText>
            </Anchor>
          </PageLinkContainer>
        ) : (
          <StyledMenuItem
            data-tooltip-id={tooltipId}
            icon={icon}
            $isActive={isPathActive}
            $isDisabled={!enabled}
            component={<SidebarLink to={link} />}>
            {!collapsed && label}
            {extraIcon && !collapsed && (
              <>
                <StyledExtraButton
                  data-tooltip-id={`${tooltipId}-extra`}
                  $collapsed={collapsed}
                  $isPathActive={isPathActive}
                  onClick={extraIconOnClick}>
                  {extraIcon}
                </StyledExtraButton>

                <ReactTooltip
                  id={`${tooltipId}-extra`}
                  positionStrategy="fixed"
                  place="right"
                  content={extraIconTooltip}
                  offset={25}
                />
              </>
            )}
          </StyledMenuItem>
        )}
        {buildTooltip({ id: tooltipId, content: label })}
      </StyledNavMenuItem>
    );
  };
  const menuItemStyles = {
    button: () => {
      return {
        '&:hover': {
          backgroundColor: '#454545',
          borderTopRightRadius: '25px',
          borderBottomRightRadius: '25px',
          marginRight: '0px',
        },
      };
    },
  };

  const customLogos = {
    iscpg: {
      logo: ClientLogoIscpg,
      height: 100,
      width: 100,
      collapsedHeight: 42,
      collapsedWidth: 42,
      margingLeft: '30px',
      collapsedMargingLeft: '0px',
    },
  };

  const getLogo = () => {
    if (config.isGs1Flavor)
      return collapsed ? (
        <div style={{ margin: 'auto' }}>
          <img src={gs1_mexico_logo_sm} alt="gs1mex-logo" height={35} width={35} />
        </div>
      ) : (
        <img src={gs1_mexico_logo} alt="gs1mex-logo" height={100} width={110} style={{ marginLeft: 'auto' }} />
      );

    if (logo && logo in customLogos)
      return (
        <img
          src={customLogos[logo].logo}
          alt="account-logo"
          height={collapsed ? customLogos[logo].collapsedHeight : customLogos[logo].height}
          width={collapsed ? customLogos[logo].collapsedWidth : customLogos[logo].width}
          style={{
            marginLeft: collapsed ? customLogos[logo].collapsedMargingLeft : customLogos[logo].margingLeft,
          }}
        />
      );

    return collapsed ? <BopsLogoExpanded height={35} width={35} /> : <BopsTitleLogo height={35} width={125} />;
  };

  return (
    <SidebarContainer>
      <StyledSidebar>
        <Header $collapsed={collapsed}>
          {getLogo()}
          <HeaderLogo onClick={() => collapseSidebar()}>
            <FontAwesomeIcon icon={faAnglesLeft} size="lg" color="white" transform={{ flipX: collapsed }} />
          </HeaderLogo>
        </Header>
        <Separator />
        <Content>
          <Menu menuItemStyles={menuItemStyles}>{menuItems.map((menuItem) => buildMenuItem(menuItem))}</Menu>
        </Content>
        <Footer>
          {config.isGs1Flavor && (
            <Menu menuItemStyles={menuItemStyles} closeOnClick>
              {buildTooltip({ id: 'tooltip-documentation', content: t('Sidebar.Tools') })}

              <StyledSubMenu
                icon={<FontAwesomeIcon icon={faScrewdriverWrench} size="lg" />}
                $collapsed={collapsed}
                label={t('Sidebar.Tools')}
                data-tooltip-id="tooltip-documentation">
                <>
                  {buildTooltip({ id: 'tooltip-gpc', content: t('Sidebar.Gpc') })}
                  <StyledMenuItem href="https://gpc-browser.gs1.org/" target="_blank" rel="noreferrer">
                    {t('Sidebar.Gpc')}
                  </StyledMenuItem>

                  {buildTooltip({ id: 'tooltip-syncfonia', content: t('Sidebar.Syncfonia') })}
                  <StyledMenuItem href="https://gs1mexicoprod.riversand.com/" target="_blank" rel="noreferrer">
                    {t('Sidebar.Syncfonia')}
                  </StyledMenuItem>

                  {buildTooltip({ id: 'tooltip-verified', content: t('Sidebar.Verified') })}
                  <StyledMenuItem href="https://www.gs1mexico.org/verified" target="_blank" rel="noreferrer">
                    {t('Sidebar.Verified')}
                  </StyledMenuItem>
                </>
              </StyledSubMenu>
            </Menu>
          )}

          <Menu menuItemStyles={menuItemStyles} closeOnClick>
            {buildTooltip({ id: 'tooltip-documentation', content: t('Sidebar.Toolkit') })}
            <StyledSubMenu
              icon={<IconArchive />}
              $collapsed={collapsed}
              label={t('Sidebar.Toolkit')}
              data-tooltip-id="tooltip-documentation">
              {(dataRepository || config.isGs1Flavor) && (
                <StyledMenuItem
                  disabled={!dataRepository}
                  component={<SidebarLink to={`/documentation/${dataRepository}`} />}>
                  {t('Sidebar.Documentation')}
                </StyledMenuItem>
              )}

              {config.isGs1Flavor && (
                <>
                  {buildTooltip({ id: 'tooltip-certification', content: t('Sidebar.Certification') })}
                  <StyledMenuItem disabled data-tooltip-id="tooltip-certification" onClick={() => {}}>
                    {t('Sidebar.Certification')}
                  </StyledMenuItem>
                </>
              )}

              {(showResources || config.isGs1Flavor) && (
                <>
                  {showResources && <ModalResourceVideos ref={modalResourceRef} />}
                  {buildTooltip({ id: 'tooltip-resources', content: t('Sidebar.TrainingVideos') })}
                  <StyledMenuItem
                    disabled={!showResources}
                    data-tooltip-id="tooltip-resources"
                    onClick={() => modalResourceRef.current?.showModal()}>
                    {t('Sidebar.TrainingVideos')}
                  </StyledMenuItem>
                </>
              )}

              {!config.isGs1Flavor && (
                <>
                  {buildTooltip({ id: 'tooltip-Insights', content: t('Sidebar.Insights') })}
                  <StyledMenuItem href="https://www.bops.ai/resources-hub" target="_blank" rel="noreferrer">
                    {t('Sidebar.Insights')}
                  </StyledMenuItem>
                </>
              )}
            </StyledSubMenu>
          </Menu>

          {buildTooltip({ id: 'tooltip-logout', content: t('Sidebar.Logout') })}
          <LogoutContainer data-tooltip-id="tooltip-logout" onClick={onLogout}>
            <FontAwesomeIcon icon={faSignOut} size="lg" transform={{ flipX: true }} />
            <SideBarText $collapsed={collapsed}>{t('Sidebar.Logout')}</SideBarText>
          </LogoutContainer>
        </Footer>
        {showResources && <ModalResourceVideos ref={modalResourceRef} />}
      </StyledSidebar>
    </SidebarContainer>
  );
};

export default Sidebar;
