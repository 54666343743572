import styled from 'styled-components';

export const Div = styled.div`
  color: ${(props) => props.theme.colors.fontGray};
`;

export const Container = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ColumnIcon = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 10px 10px 10px 10px;
`;

export const Icon = styled.div<{ active?: boolean }>`
  cursor: pointer;
  color: ${({ active, theme }) => (active ? theme.colors.bopsPurple : theme.colors.fontGray)};
  border-radius: 100%;
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 10px ${(props) => props.theme.colors.shadow};
  transition-duration: 0.5s;

  &:hover {
    color: ${({ active, theme }) => (active ? theme.colors.bopsPurpleHover : theme.colors.cardBackground5)};
    border-color: ${({ active, theme }) => (active ? theme.colors.bopsPurpleHover : theme.colors.cardBackground5)};
  }

  &:active {
    color: ${(props) => props.theme.colors.bopsPurpleActive};
    border-color: ${(props) => props.theme.colors.bopsPurpleActive};
  }
`;

export const DropdownContainer = styled.div`
  position: absolute;
  margin-top: 55px;
  margin-right: 10px;
  border-radius: 5px;
  background-color: ${(props) => props.theme.colors.cardBackground};
  border: 1px solid ${(props) => props.theme.colors.cardBorder2};
  box-shadow: 0 0 25px #1a1a1a;
  padding: 20px;
`;

export const LauncherTitle = styled.div`
  margin-bottom: 15px;
  color: ${(props) => props.theme.colors.fontGray};
  font-weight: 700;
`;

export const TopContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const ListItem = styled.div`
  padding-top: 8px;
  color: ${(props) => props.theme.colors.fontGray};
`;

export const ListItemLabel = styled.label``;

export const ListItemInput = styled.input``;
