import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import { NotFound } from '@Components';
import { Permission } from '@Models';
import { bopsApi } from '@Network';
import { RootState } from '@Store';

interface MFACodeProps {
  hasPermission: boolean;
}

const MFACode = ({ hasPermission }: MFACodeProps) => {
  const [message, setMessage] = useState('');
  const params = useParams();
  const { phoneNumber } = params;

  useEffect(() => {
    if (!phoneNumber || !hasPermission) return;
    bopsApi
      .getMFACode(phoneNumber)
      .then((res) => setMessage(res))
      .catch((err) => {
        const { response, message: errorMessage } = err || {};
        const { data } = response || {};
        const { detail } = data || {};
        setMessage(`${errorMessage} - ${detail}`);
      });
  }, [phoneNumber]);

  return hasPermission ? <p>{message}</p> : <NotFound />;
};

const mapStateToProps = (state: RootState) => {
  const { authorization } = state;

  const { permissions } = authorization;
  const hasPermission = permissions.includes(Permission.MFACode);

  return { hasPermission };
};

export default connect(mapStateToProps)(MFACode);
