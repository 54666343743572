import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: 0 1px 3px rgb(0 0 0/12%), 0 1px 2px rgb(0 0 0/24%);
  border-radius: 4px;
  box-sizing: border-box;
  min-height: 100px;
  width: 100%;
  margin: 0px auto;

  padding: 15px 15px 0px 15px;

  @media ${(props) => props.theme.breakpoints.desktopLarge} {
    padding: 15px 20px 0px 20px;
  }
`;

export const Title = styled.h4`
  text-align: left;
`;

export const Value = styled.h1`
  margin: auto;
`;
