import styled from 'styled-components';

/* eslint-disable import/prefer-default-export */
export const Container = styled.div`
  width: 100%;
  box-sizing: border-box;

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    padding: 10px 20px;
  }
`;

export const InnerContainer = styled.div`
  margin: auto;
  > *:not(:last-child) {
    margin-bottom: 20px;
  }
  padding: 40px 20px;
`;
