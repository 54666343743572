import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { InnerContainer, Title as LoadingTitle } from '../Loading/loading.styles';

const maxWidthChanged = '950px'; // set this to tablet
const smallWidthChanged = '520px'; // set this to phone

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  border-radius: 0.8rem;
  min-height: 80px;

  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  box-shadow: 0 1px 3px rgb(0 0 0/12%), 0 1px 2px rgb(0 0 0/24%);
  background: hsl(220deg 14% 97%);
  text-align: left;
  background-color: white;
  position: relative;
  border: 1px solid #eee;
  transition: 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55);
  color: black;
  padding: 0px 20px;
  box-sizing: border-box;
`;

export const FilenameLabel = styled.h4`
  margin: 8px 0px 10px 0px;
  font-size: 20px;
  box-sizing: border-box;
  color: #333;
  position: absolute;
  bottom: 8px;

  @media (max-width: ${maxWidthChanged}) {
    font-size: 16px;
  }

  @media (max-width: ${smallWidthChanged}) {
    font-size: 15px;
    word-wrap: break-word;
    width: 185px;
    vertical-align: middle;
    height: 30px;
    margin: 12px 0px 6px 0px;
    display: flex;
    align-items: center;
  }
`;

export const DateLabel = styled.div`
  font-size: 14px;
  color: #8d8b8b;
  margin-top: 10px;

  @media (max-width: ${maxWidthChanged}) {
    font-size: 12px;
  }
`;

export const TitleContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 0;
`;

export const FileContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-top: auto;
  margin-bottom: auto;

  @media (max-width: ${maxWidthChanged}) {
    flex-direction: column;
  }
`;

const buttonCommon = css`
  font-size: 0.8em;
  padding: 3px 12px;
  border-radius: 6px;
`;

export const UploadButton = styled.label`
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  margin-left: 10px;

  border-radius: 10px;
  background-color: #efeeee;

  input {
    display: none;
  }

  @media (max-width: ${maxWidthChanged}) {
    margin-left: 20px;
    margin-top: 3px;

    ${buttonCommon}
  }
`;

export const InputFile = styled.input``;

export const DownloadButton = styled.label`
  margin-left: auto;
  margin-right: 0;

  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  margin-left: 20px;
  border-radius: 10px;
  background-color: #efeeee;
  cursor: pointer;

  @media (max-width: ${maxWidthChanged}) {
    margin-bottom: 3px;

    ${buttonCommon}
  }
`;

export const StyledIcon = styled(FontAwesomeIcon).attrs(() => ({
  size: '1x',
  color: '#808080',
}))`
  margin-right: 10px;
`;

export const LoadingDiv = styled.div`
  ${LoadingTitle} {
    margin: auto 20px auto 0px;
    font-size: 0.9em;
  }

  ${InnerContainer} {
    display: flex;
    flex-direction: row;
  }
`;
