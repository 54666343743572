import jwt_decode, { JwtPayload } from 'jwt-decode';
import { GetTokenSilentlyOptions } from '@auth0/auth0-react';

import { Permission } from '@Models';
import config from '../config';

declare module 'jwt-decode' {
  export interface JwtPayload {
    permissions: Permission[];
  }
}

// TODO: this file probably needs to be in another directory

let getAccessTokenSilently: (options?: GetTokenSilentlyOptions | undefined) => Promise<string>;

const setAccessTokenSilently = (func: (options?: GetTokenSilentlyOptions | undefined) => Promise<string>) => {
  getAccessTokenSilently = func;
};

const getAccessToken = (): Promise<string> => {
  return getAccessTokenSilently({
    authorizationParams: { audience: config.REACT_APP_AUTH0_AUDIENCE },
  });
};

const decodeToken = (token: string): JwtPayload => {
  return jwt_decode<JwtPayload>(token);
};

export { decodeToken, getAccessToken, setAccessTokenSilently };
