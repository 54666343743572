import { useState } from 'react';

import { Container, Message, StyledIconClose } from './messagePopup.styles';

const MessagePopup = ({ message }) => {
  const [isOpen, setIsOpen] = useState(true);

  const closePopup = () => {
    setIsOpen(false);
  };

  if (!isOpen) {
    return null;
  }

  return (
    <Container>
      <Message>{message}</Message>
      <StyledIconClose onClick={closePopup} />
    </Container>
  );
};

export default MessagePopup;
