import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ErrorMessage, Loading } from '@Components';
import { useFetchData } from '@CustomHooks';
import { bopsApi } from '@Network';
import { RootState } from '@Store';
import Button from '../Button/button';
import { ButtonContainer, Container, InnerContainer, Title } from './requestCredentials.styles';

const RequestCredentials = ({ connectorTypes }: { connectorTypes: string[] }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { data, isLoading, error } = useFetchData(bopsApi.getCredentials, connectorTypes);
  if (isLoading) return <Loading title={t('Credentials.Loading')} />;
  if (error || !data) return <ErrorMessage internalMessage={`Unable to get credentials: ${error}`} />;

  const hasCredentials = data.length > 0;

  return (
    <Container>
      <InnerContainer>
        {hasCredentials && (
          <>
            <Title>{t('Credentials.HasCredentials')}</Title>
            <ButtonContainer>
              <Button
                buttonName="SEE_DOCUMENTATION"
                onClick={() => {
                  navigate('/documentation');
                }}>
                {t('Credentials.HasCredentialsButton')}
              </Button>
            </ButtonContainer>
          </>
        )}
        {!hasCredentials && (
          <>
            <Title>{t('Credentials.RequestCredentials')}</Title>
            <ButtonContainer>
              <Button
                buttonName="SET_CREDENTIALS"
                onClick={() => {
                  navigate('/credentials');
                }}>
                {t('Credentials.RequestCredentialsButton')}
              </Button>
            </ButtonContainer>
          </>
        )}
      </InnerContainer>
    </Container>
  );
};

const mapStateToProps = (state: RootState) => {
  const { client } = state;
  const { configuration } = client;
  const { connectorTypes } = configuration || {};
  return { connectorTypes: connectorTypes || [] };
};

export default connect(mapStateToProps)(RequestCredentials);
