import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useProSidebar } from 'react-pro-sidebar';
import { connect } from 'react-redux';
import { matchPath, Outlet, useLocation } from 'react-router-dom';
import { faBars } from '@fortawesome/free-solid-svg-icons';

import { ErrorMessage, FullPageLoading, InvalidAccountMessage, MessagePopup, Sidebar } from '@Components';
import { ClientConfiguration, Permission } from '@Models';
import { AppDispatch, clientOperations, RootState, useAppDispatch } from '@Store';
import { Container, Main, SidebarButton, StyledIcon, Title } from './withSidebar.styles';

interface WithSidebarProps {
  configuration: ClientConfiguration;
  isFetching: boolean;
  error: string;
  fetchClientConfiguration: () => void;
}

const withSidebar = ({ configuration, isFetching, error, fetchClientConfiguration }: WithSidebarProps) => {
  const { toggleSidebar } = useProSidebar();
  const currentPath = useLocation().pathname;
  const { t } = useTranslation();

  useEffect(() => {
    if (!configuration && !error) fetchClientConfiguration();
  }, [configuration, error]);

  if (isFetching || (!configuration && !error)) return <FullPageLoading />;
  const isInvalidAccount = error && error.includes('Invalid account');

  // TODO this should be obtained from a property in the page
  const titles = [
    { label: t('Sidebar.Dashboard'), link: '/recommendation' },
    { label: t('Sidebar.Monitor'), link: '/monitor' },
    { label: t('Sidebar.SecondaryMonitor'), link: '/monitor2' },
    { label: t('Sidebar.Configure'), link: '/configure' },
  ];

  const title = titles.find((titleLink) => matchPath(currentPath, titleLink.link))?.label || '';
  const { popupMessage } = configuration || {};
  return (
    <Container>
      <Sidebar />
      {error &&
        (isInvalidAccount ? (
          <InvalidAccountMessage />
        ) : (
          <ErrorMessage internalMessage={`Error fetching client configuration: ${error}`} />
        ))}
      {!error && (
        <Main>
          {popupMessage && <MessagePopup message={popupMessage} />}
          <SidebarButton onClick={() => toggleSidebar()}>
            <StyledIcon icon={faBars} size="lg" />
            <Title>{title}</Title>
          </SidebarButton>
          <Outlet />
        </Main>
      )}
    </Container>
  );
};

const mapStateToProps = (state: RootState) => {
  const { client } = state;
  const { configuration, isFetching, error } = client;
  return { configuration, isFetching, error };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  const { fetchClientConfiguration } = clientOperations;

  return {
    fetchClientConfiguration: () => dispatch(fetchClientConfiguration()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withSidebar);
