import { RecommendationType } from '@Models';
import SquareIcon from '../SquareIcon/squareIcon';
import { Container, InnerContainer, ProductDescription, Title, TitleContainer } from './recommendationCard.styles';

interface RecommendationCardProps {
  type: RecommendationType;
  locationCode: string;
  productCode: string;
  productDescription: string;
}

const RecommendationCard = ({ type, locationCode, productCode, productDescription }: RecommendationCardProps) => {
  const prefix: Map<RecommendationType, string> = new Map<RecommendationType, string>([
    ['Important Overstock', 'Promote '],
    ['Overstock', 'Promote '],
    ['Potential Overstock', 'Promote '],
    ['Understock', 'Purchase '],
    ['Potential Understock', 'Purchase '],
    ['Stockout', 'Purchase '],
    ['Potential Stockout', 'Purchase '],
  ]);

  const postfix: Map<RecommendationType, string> = new Map<RecommendationType, string>([
    ['Important Overstock', ' to reduce overstock.'],
    ['Overstock', ' to reduce overstock.'],
    ['Potential Overstock', ' to reduce overstock.'],
    ['Understock', ' to reduce risk of stockout.'],
    ['Potential Understock', ' to reduce risk of stockout.'],
    ['Stockout', ' to avoid stockout'],
    ['Potential Stockout', ' to avoid stockout'],
  ]);

  const productText = () => {
    return ` (${productCode}${locationCode ? ` at ${locationCode}` : ''})`;
  };
  return (
    <Container>
      <TitleContainer>
        <SquareIcon />
        <Title>{type[0].toLocaleUpperCase() + type.substring(1).toLocaleLowerCase()}</Title>
      </TitleContainer>
      <InnerContainer>
        {prefix.get(type)}
        <ProductDescription>{productDescription}</ProductDescription>
        {productText()}
        {postfix.get(type)}
      </InnerContainer>
    </Container>
  );
};

export type { RecommendationCardProps, RecommendationType };
export default RecommendationCard;
