import { useTranslation } from 'react-i18next';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

import { sendErrorEvent } from '@Analytics';
import MessageIcon from '../MessageIcon/messageIcon';

const ErrorMessage = ({ message, internalMessage }: { message?: string; internalMessage: string }) => {
  const { t } = useTranslation();

  const finalMessage = message || 'Global.ErrorMessage';

  sendErrorEvent(internalMessage);

  return <MessageIcon message={t(finalMessage)} icon={faTriangleExclamation} iconSize="3x" iconColor="darkred" />;
};

ErrorMessage.defaultProps = {
  message: undefined,
};

export default ErrorMessage;
