import { useEffect } from 'react';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';

import { authorizationOperations, setAccessTokenSilently, useAppDispatch } from '@Store';
import FullPageLoading from '../FullPageLoading/fullPageLoading';

const AuthenticationGuard = ({ component }) => {
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useAppDispatch();

  useEffect(() => {
    setAccessTokenSilently(getAccessTokenSilently);

    const { updateTokenData } = authorizationOperations;
    dispatch(updateTokenData());
  }, []);

  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => <FullPageLoading />,
  });

  return <Component />;
};

export default AuthenticationGuard;
