import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import { FullPageLoading } from '@Components';
import { AppDispatch, MiscellaneousActions, MiscellaneousState } from '@Store';

const Home = ({ updateAuthenticationError }: { updateAuthenticationError: (error: string) => void }) => {
  const { logout } = useAuth0();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const error = searchParams.get('error');
  const errorDescription = searchParams.get('error_description');

  useEffect(() => {
    if (!error) {
      navigate('/landing', { replace: true });
      return;
    }

    updateAuthenticationError(errorDescription || '');
    logout({ returnTo: `${window.location.origin}/no-access` });
    // TODO log error
  }, [error]);

  return <FullPageLoading />;
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  const { updateValue: dispatchUpdateValue } = MiscellaneousActions;

  return {
    updateAuthenticationError: (error: string) => dispatch(dispatchUpdateValue({ authenticationError: error })),
  };
};

export default connect(null, mapDispatchToProps)(Home);
