import { useTranslation } from 'react-i18next';

import { ErrorMessage, Loading, withValidPermission } from '@Components';
import { useFetchData } from '@CustomHooks';
import { Permission } from '@Models';
import { bopsApi } from '@Network';
import Supplier from './suppliers';

const SupplierContainer = () => {
  const { t } = useTranslation();

  const { data, isLoading, error, refetch } = useFetchData(bopsApi.getSuppliers);

  if (isLoading) return <Loading title={t('Supplier.Loading')} />;

  if (error || !data) {
    return <ErrorMessage internalMessage={`Unable to get suppliers: ${error}`} />;
  }

  const supplierUpdated = () => {
    refetch();
  };

  return <Supplier suppliers={data} supplierUpdated={supplierUpdated} />;
};

export default withValidPermission(SupplierContainer, Permission.ManageSuppliers);
