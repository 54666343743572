import { useState } from 'react';
import { connect } from 'react-redux';

import { AiRecommendation, Claim, Permission } from '@Models';
import { RootState, useGetAiRecommendationsByIdsQuery } from '@Store';
import config from '../../config';
import { OpenAiItemData } from '../OpenAiAssistant/openAiAssistant.container';
import RecommendationAiAssistant from './recommendationAiAssistant';

interface RecommendationAiAssistantContainerProps {
  recommendationsId: string[];
  showPrompt: boolean;
  showComponent: boolean;
  language?: string | null;
}

const RecommendationAiAssistantContainer = ({
  recommendationsId,
  showPrompt,
  showComponent,
  language,
}: RecommendationAiAssistantContainerProps) => {
  const getPrompt = () => {
    let textPrompt =
      config.REACT_APP_OPEN_AI_PROMPT ||
      `Justify the policy action in this json data using the other data in the object and respond very concisely and use numbers`;

    const translatedPrompts = { spanish: ' also the response must be in spanish' };
    if (language && language in translatedPrompts) {
      textPrompt = textPrompt.concat(translatedPrompts[language]);
    }

    textPrompt = textPrompt.concat(': \n\n');

    return textPrompt;
  };

  const [prompt, setPrompt] = useState<string>(getPrompt());
  const {
    data: aiRecommendations,
    error,
    isLoading,
    isFetching,
  } = useGetAiRecommendationsByIdsQuery(recommendationsId);

  if (!showComponent) return null;
  if (error) {
    console.log(error);
  }

  const buildSubtitle = ({ productDescription, productCode, locationCode }: AiRecommendation): string =>
    `${productDescription} (${productCode}${locationCode ? ` at ${locationCode}` : ''})`;

  const aiItemsData =
    aiRecommendations?.map((recommendation) => ({
      title: recommendation.recommendedActionType,
      subtitle: buildSubtitle(recommendation),
      payload: JSON.stringify(recommendation),
    })) || [];

  return (
    <RecommendationAiAssistant
      isLoading={isLoading || isFetching}
      items={aiItemsData}
      prompt={prompt}
      setPrompt={setPrompt}
      showPrompt={showPrompt}
    />
  );
};

const mapStateToProps = (state: RootState) => {
  const { authorization, client } = state;
  const { permissions }: { permissions: Permission[] } = authorization;

  const showPrompt = permissions.includes(Permission.DevOpenAiPrompt);
  const showComponent = permissions.includes(Permission.OpenAiMonitor);

  const { configuration } = client;
  const { language } = configuration;

  return { showPrompt, showComponent, language };
};

export default connect(mapStateToProps)(RecommendationAiAssistantContainer);

export type { OpenAiItemData };
